import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { BankingInformationService } from './banking-information.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'pt-banking-information-confirm',
  templateUrl: './banking-information-confirm.component.html',
})
export class BankingInformationConfirmComponent implements OnInit {
  success$: Observable<boolean>;

  constructor(
    private activeRoute: ActivatedRoute,
    private bankingInfoService: BankingInformationService
  ) {}

  ngOnInit(): void {
    this.success$ = this.activeRoute.params.pipe(
      map((params) => params['token']),
      switchMap((token) => this.bankingInfoService.verifyToken(token)),
      shareReplay(1)
    );
  }
}
