import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userPosition',
})
export class UserPositionPipe implements PipeTransform {
  transform(userPositionKey: string): string {
    let userPositionValue;
    switch (userPositionKey) {
      case 'NO_POSITION':
        userPositionValue = $localize`Keine Position`;
        break;
      case 'ACCOUNTING':
        userPositionValue = $localize`Buchhaltung`;
        break;
      case 'CONTROLLING':
        userPositionValue = $localize`Controlling`;
        break;
      case 'MANAGER':
        userPositionValue = $localize`Geschäftsführer/Firmeninhaber`;
        break;
      case 'KEY_ACCOUNT':
        userPositionValue = $localize`Key Account`;
        break;
      case 'MARKETING':
        userPositionValue = $localize`Marketing`;
        break;
      case 'SALE_BACKOFFICE':
        userPositionValue = $localize`Verkauf Innendienst`;
        break;
      case 'SALE_FIELDWORK':
        userPositionValue = $localize`Verkaufsmitarbeiter Außendienst`;
        break;
      case 'QUALITYMANAGEMENT':
        userPositionValue = $localize`Qualitätsmanagement`;
        break;
      default:
        userPositionValue = userPositionKey;
        break;
    }
    return userPositionValue;
  }
}
