<p-fileUpload
  [customUpload]="true"
  mode="advanced"
  [accept]="acceptedFileTypes"
  [maxFileSize]="maxFileSize"
  [showUploadButton]="false"
  [showCancelButton]="false"
  i18n-chooseLabel
  chooseLabel="Datei wählen"
  i18n-uploadLabel
  uploadLabel="Hochladen"
  i18n-cancelLabel
  cancelLabel="Abbrechen"
  i18n-invalidFileSizeMessageDetail
  invalidFileSizeMessageDetail="Die Datei darf maximal {0} groß sein."
  i18n-invalidFileSizeMessageSummary
  invalidFileSizeMessageSummary="{0}: Ungültige Dateigröße, "
  i18n-invalidFileTypeMessageDetail
  invalidFileTypeMessageDetail="erlaubte Dateitypen sind: {0}."
  (onSelect)="fileSelected($event)"
  (onRemove)="onDelete()"
  [files]="files"
  [disabled]="disabled"
  styleClass="file-upload-component"
></p-fileUpload>
