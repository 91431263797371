import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RegistrationStateEventSource } from '../shared/services/registration-state/registration-state-event-source.service';
import { skipWhile, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SupplierAreaGuard {
  constructor(
    private router: Router,
    private registrationState: RegistrationStateEventSource
  ) {}

  canActivate(): Observable<boolean> {
    return this.registrationState.supplierState$.pipe(
      skipWhile((supplierState) => supplierState === undefined),
      switchMap((supplierState) => {
        switch (supplierState) {
          case 'ACTIVE':
            // if Supplier is ACTIVE, we are good to go ...
            return of(true);
          case 'APPLYING':
          case 'PENDING':
          case 'REVIEWED':
          case 'APPROVED':
            // if Supplier is not yet active, redirect to registration forms
            return this.router
              .navigate(['supplier-registration'])
              .then(() => false);
          default:
            console.error(
              `Unhandled Supplier state in SupplierAreaGuard: ${supplierState}`
            );
            return this.router.navigate(['login']).then(() => true);
        }
      })
    );
  }
}
