import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * When applied to text input, blank user input is transformed to null value in the model.
 */
@Directive({
  selector: 'input[shBlankToNull]',
})
export class BlankToNullDirective {
  constructor(control: NgControl) {
    // monkey-patch the viewToModelUpdate method to treat blank as null
    const originalViewToModelUpdate = control.viewToModelUpdate;
    control.viewToModelUpdate = (value) => {
      if (value === '') {
        control.reset(null);
        return;
      }
      originalViewToModelUpdate.call(control, value);
    };
  }
}
