import { ControlValueAccessor } from '@angular/forms';

/**
 * This is needed as bridge between DOM component and Angular, so it's value and status can be transferred.
 * @amayr
 */
export class CustomControlValueAccessor<T> implements ControlValueAccessor {
  private _value: T;

  public get value(): T {
    return this._value;
  }

  public set value(value: T) {
    this.writeValue(value);
  }

  private _disabled: boolean;
  public get disabled(): boolean {
    return this._disabled;
  }

  /**
   * use this function to emit value to parent component.
   * @param value
   */
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  propagateChange: (value: T) => void = () => {};

  /**
   * Registers the callback function if component value has been changed.
   * @param fn
   */
  registerOnChange(fn: (value: T) => void): void {
    this.propagateChange = fn;
  }

  /**
   * no-op
   */
  registerOnTouched(): void {
    // noop
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  /**
   * Called when value is set from parent (form.get('xxx').patchValue('newVal').
   * @param value
   */
  writeValue(value: T): void {
    this._value = value;
    this.propagateChange(value);
  }
}
