import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  map,
  repeatWhen,
  shareReplay,
  skipUntil,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { UrlHelperService } from '../../util/url-helper.service';

@Injectable({
  providedIn: 'root',
})
export class AliveService implements OnDestroy {
  public readonly loggedInUserChanged$: Observable<LoggedInUserChanged>;

  readonly #start$ = new Subject<void>();
  readonly #stop$ = new Subject<void>();
  readonly #destroyed$ = new Subject<void>();
  readonly #keepAliveIntervalPeriod$ = new BehaviorSubject<number>(5000);
  #initialUserName: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private urlHelper: UrlHelperService
  ) {
    const currentUser$ = this.pollingInterval().pipe(
      takeUntil(this.#stop$),
      repeatWhen(() => this.#start$),
      skipUntil(this.#start$),
      switchMap(() =>
        this.getCurrentUser$().pipe(catchError(() => of(undefined as User)))
      ),
      shareReplay(1)
    );

    this.navigateToLoginWhenLoggedOut(currentUser$);

    this.loggedInUserChanged$ = currentUser$.pipe(
      filter((currentUser) => !!currentUser),
      filter((currentUser) => currentUser.username !== this.#initialUserName),
      map((currentUser) => ({
        currentUserName: currentUser.username,
        previousUserName: this.#initialUserName,
      })),
      shareReplay(1)
    );
  }

  public configureInterval(period: number): void {
    this.#keepAliveIntervalPeriod$.next(period);
  }

  public start(loggedInUserName: string): void {
    console.log('Starting keepalive');
    this.#initialUserName = loggedInUserName;
    this.#start$.next();
  }

  public stop(): void {
    console.log('Stopping keepalive');
    this.#stop$.next();
  }

  ngOnDestroy(): void {
    console.log('Killing keepalive');
    this.#destroyed$.next();
    this.#start$.complete();
    this.#stop$.complete();
    this.#destroyed$.complete();
  }

  private navigateToLoginPage(): void {
    this.router.navigate(['login'], {
      queryParams: { returnUrl: this.router.routerState.snapshot.url },
    });
  }

  private getCurrentUser$(): Observable<User> {
    return this.http.get<User>(this.urlHelper.prepareApiPath('me'));
  }

  private pollingInterval(): Observable<number> {
    return this.#keepAliveIntervalPeriod$.pipe(
      switchMap((keepAliveInterval) => interval(keepAliveInterval))
    );
  }

  private navigateToLoginWhenLoggedOut(currentUser$: Observable<User>): void {
    currentUser$
      .pipe(
        filter((user) => !user),
        takeUntil(this.#destroyed$)
      )
      .subscribe(() => {
        console.log('User no longer logged in, redirecting to login page');
        this.navigateToLoginPage();
      });
  }
}

interface User {
  username: string;
}

export interface LoggedInUserChanged {
  previousUserName: string;
  currentUserName: string;
}
