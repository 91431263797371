import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-custom-errors',
  templateUrl: './custom-form-errors.component.html',
  styleUrls: ['./custom-form-errors.component.scss'],
})
export class CustomFormErrorsComponent {
  @Input() errors: string[] | string;

  @Input() isVisible = false;

  @Input() customClass? = 'sh-custom-form-error';

  get listClass(): string {
    return this.customClass ? this.customClass + '__list' : '';
  }

  get listItemClass(): string {
    return this.customClass ? this.customClass + '__list-item' : '';
  }

  get errorMessages(): string[] {
    const errorMsgs: string[] = [];

    if (this.errors instanceof Array) {
      this.errors.forEach((error) => {
        errorMsgs.push(error);
      });
    } else {
      errorMsgs.push(this.errors);
    }
    return errorMsgs;
  }
}
