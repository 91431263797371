import { ValidatorFn } from '@angular/forms';
import * as CommonsValidator from 'commons-validator-js';

const validator = new CommonsValidator.EmailValidator();

/**
 * Validates email based on rules of `apache.commons.validator` which is also used in backend. This prevents differing email validation rules in front- and backend.
 *
 * Eg. `test@test` is valid in standard angular validation, but invalid in `apache.commons.validator` validation which
 * is used in backend.
 */
export const emailValidator: ValidatorFn = (control) => {
  const value: string = control.value;

  if (value) {
    if (!validator.isValid(value)) {
      return { emailInvalid: {} };
    }
  }

  return undefined;
};
