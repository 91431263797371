<!--  error-component error-label -->
<div
  *ngIf="errorMessages?.length > 0 && isVisible"
  class="sh-custom-form-error"
  [ngClass]="customClass"
>
  <ul
    class="sh-custom-form-error__list"
    *ngFor="let errorMessage of errorMessages"
    [ngClass]="listClass"
  >
    <li class="sh-custom-form-error__list-item" [ngClass]="listItemClass">
      &hellip; {{ errorMessage }}
    </li>
  </ul>
</div>
