import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.scss'],
})
export class InfoTextComponent {
  @Input() text: string;
  @Input() fullHeight = false;
  @Input() severity: 'info' | 'warn' | 'error' | 'success' = 'info';
  @Input() iconClass: string;
}
