import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'measuringAccuracyValue',
})
export class MeasuringAccuracyValuePipe implements PipeTransform {
  transform(measuringAccuracy: unknown): string {
    switch (measuringAccuracy) {
      case 'G':
        return $localize`100 Gramm`;
      case 'ML':
        return $localize`100 ML`;
      default:
        return '';
    }
  }
}
