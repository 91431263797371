import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmptyOrUndefined } from '@topteam-ui/global-shared/util/string-util';
import { PasswordResetService } from '../../../services/password-reset/password-reset.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import {
  FormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { passwordValidator } from '@topteam-ui/global-shared/validators/password-validator';
import { ToastService } from '@topteam-ui/global-shared/services';
import { markAllFormControls } from '@topteam-ui/global-shared/util/reactive-form-util';

enum PasswordResetState {
  TOKEN_INVALID,
  PENDING,
  SUCCESSFUL,
}

const passwordRepeatValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  const passwordControl = fg.get('password1');
  const repeatControl = fg.get('password2');
  const error = { passwordsDontMatch: {} };

  return (passwordControl.touched || repeatControl.touched) &&
    passwordControl.value !== repeatControl.value
    ? error
    : undefined;
};

@Component({
  selector: 'pt-portal-passwordreset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  state: PasswordResetState = PasswordResetState.PENDING;
  PasswordResetState = PasswordResetState;
  passwordReset: PasswordResetForm;
  private token: string;
  #destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private passwordResetService: PasswordResetService,
    private formBuilder: FormBuilder,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.params.token;

    this.isTokenValid()
      .pipe(takeUntil(this.#destroy$))
      .subscribe((val) => {
        if (!val) {
          this.state = PasswordResetState.TOKEN_INVALID;
          return;
        }
      });

    this.initForm();
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  onSubmitPasswordReset(): void {
    if (!this.passwordReset.valid) {
      markAllFormControls(this.passwordReset);
      this.toastService.formErrorsToast();
      return;
    }

    const passwordCtrl = this.passwordReset.get('password1');

    this.passwordResetService
      .updatePassword(this.token, passwordCtrl.value)
      .pipe(takeUntil(this.#destroy$))

      .subscribe((result) => {
        if (result.success === true) {
          this.state = PasswordResetState.SUCCESSFUL;
          return;
        }

        switch (result.error) {
          case 'OLD_PASSWORD_EQUALS_NEW_PASSWORD':
            passwordCtrl.setErrors({ passwordEqualsOldPassword: true });
            break;
          default:
            this.toastService.errorToast(
              $localize`Unbekannter Fehler`,
              $localize`Unbekannter Fehler`
            );
            break;
        }
      });
  }

  private initForm(): void {
    this.passwordReset = this.buildForm();
  }

  private isTokenValid(): Observable<boolean> {
    if (isEmptyOrUndefined(this.token)) {
      return of(false);
    }

    return this.passwordResetService.checkTokenValid(this.token).pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  private readonly buildForm = () => {
    return this.formBuilder.group(
      {
        password1: this.formBuilder.control('', [
          Validators.required,
          passwordValidator,
        ]),
        password2: this.formBuilder.control('', [
          Validators.required,
          passwordValidator,
        ]),
      },
      { validators: passwordRepeatValidator }
    );
  };
}

type PasswordResetForm = ReturnType<PasswordResetComponent['buildForm']>;
