import { NgModule } from '@angular/core';
import { PaymentComponent } from './payment.component';
import { SharedModule } from '../../shared/shared.module';
import { GlobalSharedModule } from '@topteam-ui/global-shared';
import { SpinnerModule } from 'primeng/spinner';
import { BankingInformationFormComponent } from './banking-information/banking-information-form.component';
import { BankingInformationViewComponent } from './banking-information/banking-information-view.component';
import { BankingInformationEditComponent } from './banking-information/banking-information-edit.component';
import { BankingInformationConfirmComponent } from './banking-information/banking-information-confirm.component';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  imports: [SharedModule, GlobalSharedModule, SpinnerModule, InputNumberModule],
  declarations: [
    PaymentComponent,
    BankingInformationFormComponent,
    BankingInformationViewComponent,
    BankingInformationEditComponent,
    BankingInformationConfirmComponent,
  ],
  exports: [PaymentComponent],
})
export class PaymentModule {}
