import { Component, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectButton } from 'primeng/selectbutton';

@Component({
  selector: 'sh-days-of-week-picker',
  templateUrl: './days-of-week-picker.component.html',
  styleUrls: ['./days-of-week-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DaysOfWeekPickerComponent),
    },
  ],
})
export class DaysOfWeekPickerComponent implements ControlValueAccessor {
  readonly options: { label: string; value: DayOfWeek }[];
  @ViewChild('selectButton', { static: true }) selectButton: SelectButton;

  constructor() {
    this.options = [
      { label: $localize`Mo`.toLocaleUpperCase(), value: 'MONDAY' },
      { label: $localize`Di`.toLocaleUpperCase(), value: 'TUESDAY' },
      { label: $localize`Mi`.toLocaleUpperCase(), value: 'WEDNESDAY' },
      { label: $localize`Do`.toLocaleUpperCase(), value: 'THURSDAY' },
      { label: $localize`Fr`.toLocaleUpperCase(), value: 'FRIDAY' },
      { label: $localize`Sa`.toLocaleUpperCase(), value: 'SATURDAY' },
      { label: $localize`So`.toLocaleUpperCase(), value: 'SUNDAY' },
    ];
  }

  registerOnChange(fn: { (): void }): void {
    this.selectButton.registerOnChange(fn);
  }

  writeValue(value: DayOfWeek[]): void {
    this.selectButton.writeValue(value);
  }

  registerOnTouched(fn: { (): void }): void {
    this.selectButton.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.selectButton.setDisabledState(isDisabled);
  }
}

export type DayOfWeek =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';
