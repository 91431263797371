import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import '../../util/string-util';

@Component({
  selector: 'sh-time-of-day-picker',
  templateUrl: './time-of-day-picker.component.html',
  styleUrls: ['./time-of-day-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimeOfDayPickerComponent),
    },
  ],
})
export class TimeOfDayPickerComponent implements ControlValueAccessor, OnInit {
  @ViewChild('calendar', { static: true }) calendar: Calendar;
  @Input() styleClass: string;

  ngOnInit(): void {
    this.calendar.timeOnly = true; // for some reason it's apparently not enough to bind this property in the template
  }

  writeValue(value: string): void {
    if (value) {
      const todayStr = getTodayAsLocalDateString(); // e.g. '2019-07-01'
      const localZoneOffsetStr = getLocalZoneOffsetString(); // e.g. '+02:00' for GMT with DST (summer time)
      const date = new Date(`${todayStr}T${value}${localZoneOffsetStr}`);
      this.calendar.writeValue(date);
    } else {
      this.calendar.writeValue(undefined);
    }
  }

  registerOnChange(fn: (value: string) => unknown): void {
    this.calendar.registerOnChange((value: Date) => {
      const timeString = value ? dateToLocalTimeString(value) : undefined;
      fn(timeString);
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: Function): void {
    this.calendar.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.calendar.setDisabledState(isDisabled);
  }
}

function getLocalZoneOffsetString(): string {
  const localZoneOffsetTotalMinutes = new Date().getTimezoneOffset();
  if (localZoneOffsetTotalMinutes === 0) {
    return 'Z'; // UTC
  }

  const localZoneOffsetHours = Math.abs(
    Math.floor(localZoneOffsetTotalMinutes / 60)
  );
  const localZoneOffsetMinutes = Math.abs(localZoneOffsetTotalMinutes % 60);
  const localZoneOffsetSignChar = localZoneOffsetTotalMinutes < 0 ? '+' : '-';
  return `${localZoneOffsetSignChar}${pad(localZoneOffsetHours)}:${pad(
    localZoneOffsetMinutes
  )}`;
}

function getTodayAsLocalDateString(): string {
  const today = new Date();
  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth() + 1; // month starts at 0 ...
  const thisDayOfMoth = today.getDate(); // ... but date starts at 1. Yes, the specs actually say so.  Really.
  return `${thisYear}-${pad(thisMonth)}-${pad(thisDayOfMoth)}`;
}

function dateToLocalTimeString(value: Date): string {
  return `${pad(value.getHours())}:${pad(value.getMinutes())}:${pad(
    value.getSeconds()
  )}`;
}

function pad(timeComponent: number): string {
  return `${timeComponent}`.padStart(2, '0');
}
