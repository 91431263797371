import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { CustomControlValueAccessor } from '../custom-control-value-accessor/CustomControlValueAccessor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileSelectEvent } from 'primeng/fileupload';

@Component({
  selector: 'sh-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileUploadComponent),
    },
  ],
})
export class FileUploadComponent extends CustomControlValueAccessor<File> {
  @Input() maxFileSize = 5000000;
  @Output() readonly selectedFileChange = new EventEmitter();
  private acceptedFileTypeRegex: RegExp;

  constructor() {
    super();
    this.acceptedFileTypes = '.xls,.xlsx,.pdf,.doc,.docx';
  }

  private _acceptedFileTypes: string;

  get acceptedFileTypes(): string {
    return this._acceptedFileTypes;
  }

  @Input()
  set acceptedFileTypes(value: string) {
    this._acceptedFileTypes = value;
    const fileTypesList = value.split(',');
    this.acceptedFileTypeRegex = new RegExp(
      `^.*(${fileTypesList.join('|')})$`,
      'i'
    );
  }

  get files(): File[] {
    return this.value ? [this.value] : [];
  }

  @Input()
  get selectedFile(): File {
    return this.value;
  }

  set selectedFile(value: File) {
    this.writeValue(value);
  }

  writeValue(file: File): void {
    super.writeValue(file);
    this.selectedFileChange.emit(file);
  }

  fileSelected(event: FileSelectEvent): void {
    let file =
      event.files && event.files.length > 0 ? event.files[0] : undefined;
    if (
      !!file &&
      (file.size > this.maxFileSize ||
        !this.acceptedFileTypeRegex.test(file.name))
    ) {
      file = undefined;
    }
    this.selectedFile = file;
  }

  onDelete(): void {
    this.writeValue(undefined);
  }
}
