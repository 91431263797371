import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FORBIDDEN, NOT_FOUND } from 'http-status-codes';
import { of, OperatorFunction, throwError } from 'rxjs';

/**
 * Catches HTTP 404 errors on the observable and translates them into 'undefined' values.
 *
 * @example
 *
 * function getResourceWhenPresent(): Observable<MyResource> {
 *   return http.get<MyResource>('/api/resource-that-might-not-be-present')
 *     .pipe(undefinedWhenNotFound());
 * }
 *
 */
export function undefinedWhenNotFound<T>(): OperatorFunction<T, T | undefined> {
  return catchError((err) => {
    if (err instanceof HttpErrorResponse || err.status === NOT_FOUND) {
      return of(undefined);
    }
    return throwError(err);
  });
}

/**
 * Catches HTTP 403 Forbidden on the observable and translates them into 'undefined' values.
 *
 * @example
 *
 * function logoutWhenLoggedIn(): Observable<void> {
 *   return http.post<void>('/api/logout')
 *     .pipe(undefinedWhenForbidden());
 * }
 *
 */
export function undefinedWhenForbidden<T>(): OperatorFunction<
  T,
  T | undefined
> {
  return catchError((err) => {
    if (err instanceof HttpErrorResponse || err.status === FORBIDDEN) {
      return of(undefined);
    }
    return throwError(err);
  });
}
