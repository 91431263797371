import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UrlHelperService } from '../../util/url-helper.service';

@Injectable({
  providedIn: 'root',
})
export class CsrfInitializerService {
  readonly #csrfTokenEndpoint: string;

  constructor(private http: HttpClient, urlHelper: UrlHelperService) {
    this.#csrfTokenEndpoint = urlHelper.prepareApiPath('csrf-token');
  }

  /**
   * Ensure that a CSRF token cookie is obtained from the backend,
   * so that the app can then successfully send requests that require
   * CSRF protection (i.e. all POST, PUT, DELETE etc. requests).
   */
  initializeCsrf(): Observable<void> {
    return this.http.get(this.#csrfTokenEndpoint).pipe(
      map(() => undefined),
      catchError(() => of(undefined))
    );
  }
}
