import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlHelperService } from '../../util/url-helper.service';
import { Assortment } from './assortment.model';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssortmentsService {
  private readonly assortments$: Observable<Assortment[]>;

  constructor(private http: HttpClient, private urlHelper: UrlHelperService) {
    this.assortments$ = this.http
      .get<Assortment[]>(this.urlHelper.prepareApiPath('assortments'))
      .pipe(shareReplay(1));
  }

  getAssortments(): Observable<Assortment[]> {
    return this.assortments$;
  }
}
