<pt-top-banner></pt-top-banner>
<div class="landing-body">
  <div class="sh-u-separator--small"></div>
  <div class="landing-wrapper">
    <div id="header">
      <div class="header-top">
        <img
          src="libs/global-shared/shared-assets/img/top-team-zentraleinkaufs-gmbh.svg"
          class="pt-landing-logo"
        />
        <ul id="landing-menu">
          <li>
            <a href="http://www.topteam.co.at" target="_blank" i18n
              >TOP-TEAM Website</a
            >
          </li>
          <li>
            <a routerLink="/article-recall" i18n>Warenrückruf</a>
          </li>
        </ul>
      </div>

      <div class="header-content">
        <h1 class="sh-h1" i18n>Login</h1>
        <form (ngSubmit)="authenticateUser()">
          <sh-form-label required="true" i18n>Benutzername</sh-form-label>
          <input
            pInputText
            type="text"
            class="login__input"
            [(ngModel)]="username"
            name="username"
            autocomplete="username"
            autofocus
          />

          <div class="sh-u-separator--x-small"></div>

          <sh-form-label required="true" i18n>Passwort</sh-form-label>
          <input
            pInputText
            type="password"
            class="login__input"
            [(ngModel)]="password"
            name="password"
            autocomplete="current-password"
          />

          <sh-custom-errors
            errors="Username oder Passwort inkorrekt oder E-Mail noch nicht validiert"
            i18n-errors
            [isVisible]="invalidCredentials"
          ></sh-custom-errors>

          <div class="sh-u-separator--small"></div>
          <p-button type="submit" i18n>Login</p-button>

          <br />

          <div class="sh-u-separator--small"></div>

          <a routerLink="/password-forgotten" i18n>Passwort vergessen?</a>
          <div class="sh-u-separator--x-small"></div>
          <a routerLink="/user-registration" i18n>Neuer Lieferant werden</a>
        </form>
      </div>
    </div>

    <div id="features">
      <h1 i18n>Aktionen</h1>
      <p i18n>Aktionen ohne vorhandenen TOP-TEAM Benutzer</p>
      <div class="grid p-align-stretch mt-5">
        <div class="col-12 md:col-12 lg:col-4">
          <div class="card feature-box sh-u-full-height">
            <i class="pi pi-users feature__icon"></i>
            <h3 i18n>Neuer Lieferant werden</h3>
            <p i18n>
              Sie kennen die Vertriebskanäle der Unternehmensgruppen Pfeiffer,
              Eurogast und Weiß und schätzen Konzerne mit Handschlagqualität?
              Dann sind Sie bei uns richtig! Wir freuen uns auf eine
              erfolgreiche Partnerschaft.<br /><br />Im Registrierungsformular
              auf dieser Webseite können Sie sich als Lieferant bewerben und uns
              Informationen über Ihre Artikel zukommen lassen.Der zuständige
              Einkaufsbereichsleiter wird diese Bewerbung schnellsmöglich prüfen
              und Sie bei Interesse kontaktieren.
            </p>
            <div class="sh-u-separator--small"></div>
            <a routerLink="/user-registration">
              <p-button i18n-label label="Registrierung starten"></p-button>
            </a>
          </div>
        </div>
        <div class="col-12 md:col-12 lg:col-4">
          <div class="card feature-box sh-u-full-height">
            <i class="pi pi-volume-up feature__icon"></i>
            <h3 i18n>Warenrückruf</h3>
            <p i18n>
              Bei einem Produktrückruf aufgrund von Qualitätsmängeln füllen Sie
              einfach unser vorgefertigtes Formular aus.<br /><br />Über die
              genaue Vorgehensweise informiert Sie unsere Kurzanleitung. Um auf
              Ihre Artikelliste zugreifen zu können, loggen Sie sich bitte
              zuerst mit Ihren Zugangsdaten ein!
            </p>
            <div class="sh-u-separator--small"></div>
            <a routerLink="/article-recall">
              <p-button i18n-label label="Warenrückruf starten"></p-button>
            </a>
          </div>
        </div>
        <div class="col-12 md:col-12 lg:col-4">
          <div class="card feature-box sh-u-full-height">
            <i class="pi pi-globe feature__icon"></i>
            <h3 i18n>TOP-TEAM Website</h3>
            <p i18n>
              Das Lieferantenportal ist ein Service der TOP-TEAM Zentraleinkauf
              GmbH.<br /><br />Weitere Informationen über die
              Unternehmensstruktur, Kompetenzen, Partner, Team, ... erhalten Sie
              auf der Webseite der TOP-TEAM Zentraleinkauf GmbH.
            </p>
            <div class="sh-u-separator--small"></div>

            <a i18n-href href="http://www.topteam.co.at" target="_blank">
              <p-button
                i18n-label
                label="TOP-TEAM Webseite besuchen"
              ></p-button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="sh-u-separator"></div>

    <sh-topteam-footer></sh-topteam-footer>
  </div>
</div>
