import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from '../util/object-util';

@Pipe({ name: 'shYesNo' })
export class YesNoPipe implements PipeTransform {
  transform(
    value: boolean | Observable<boolean>,
    notABooleanResult?: string
  ): string {
    if (!isNullOrUndefined(notABooleanResult)) {
      if (value === true) return $localize`Ja`;
      if (value === false) return $localize`Nein`;
      return notABooleanResult;
    } else return value ? $localize`Ja` : $localize`Nein`;
  }
}
