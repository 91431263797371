import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'certificateLabel',
})
export class CertificateLabelPipe implements PipeTransform {
  transform(certificateKey: string): string {
    let certificateValue = '';
    switch (certificateKey) {
      case 'AMA_GUETESIEGEL':
        certificateValue = $localize`AMA Gütesiegel`;
        break;
      case 'AMA_BIOSIEGEL_HERKUNFT_OESTERREICH':
        certificateValue = $localize`AMA Biosiegel Herkunft Österreich`;
        break;
      case 'AMA_BIOSIEGEL_INTERNATIONAL':
        certificateValue = $localize`AMA Biosiegel International`;
        break;
      case 'AMA_GUETESIEGEL_BLAU':
        certificateValue = $localize`AMA Gütesiegel blau (EU)`;
        break;
      case 'AMA_GUETESIEGEL_ROT':
        certificateValue = $localize`AMA Gütesiegel rot`;
        break;
      case 'AMA_GUETESIEGEL_WEISS':
        certificateValue = $localize`AMA Genussregion`;
        break;
      case 'AMA_GUETESIEGEL_SCHWARZ':
        certificateValue = $localize`AMA Schwarz (Bio ohne Herkunftsangabe)`;
        break;
      case 'AMA_GUETESIEGEL_GENUSSREGION':
        certificateValue = $localize`AMA Genussregion`;
        break;
      case 'ASC':
        certificateValue = $localize`ASC`;
        break;
      case 'ECOLABEL':
        certificateValue = $localize`Ecolabel`;
        break;
      case 'FSC':
        certificateValue = $localize`FSC`;
        break;
      case 'MSC':
        certificateValue = $localize`MSC`;
        break;
      case 'UTZ':
        certificateValue = $localize`UTZ`;
        break;
    }
    return certificateValue;
  }
}
