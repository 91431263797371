<form (submit)="submit()" autocomplete="off" [formGroup]="userForm">
  <div class="card">
    <h2 class="sh-h2" i18n>Registrierung</h2>

    <div class="grid" formGroupName="contact">
      <div class="md:col-6">
        <sh-form-label for="gender" required="true" i18n>Anrede</sh-form-label>
        <p-dropdown
          [options]="selectableGenders"
          id="gender"
          styleClass="sh-u-full-width"
          formControlName="gender"
        ></p-dropdown>
        <sh-form-errors
          [for]="userForm.controls.contact.controls.gender"
        ></sh-form-errors>
      </div>

      <div class="md:col-6">
        <sh-form-label for="title" i18n>Titel</sh-form-label>
        <input
          pInputText
          class="sh-u-full-width"
          type="text"
          id="title"
          formControlName="title"
        />
        <sh-form-errors
          [for]="userForm.controls.contact.controls.title"
        ></sh-form-errors>
      </div>

      <div class="md:col-6">
        <sh-form-label for="firstname" required="true" i18n
          >Vorname</sh-form-label
        >
        <input
          pInputText
          class="sh-u-full-width"
          type="text"
          id="firstname"
          formControlName="firstname"
          required
        />
        <sh-form-errors
          [for]="userForm.controls.contact.controls.firstname"
        ></sh-form-errors>
      </div>

      <div class="md:col-6">
        <sh-form-label for="lastname" required="true" i18n
          >Nachname</sh-form-label
        >
        <input
          pInputText
          class="sh-u-full-width"
          type="text"
          id="lastname"
          formControlName="lastname"
        />
        <sh-form-errors
          [for]="userForm.controls.contact.controls.lastname"
        ></sh-form-errors>
      </div>

      <div class="col-6">
        <sh-form-label for="position" required="true" i18n
          >Position</sh-form-label
        >
        <p-dropdown
          id="position"
          [options]="userPositions$ | async"
          styleClass="sh-u-full-width"
          formControlName="position"
        ></p-dropdown>
      </div>
    </div>

    <div class="md:col-12 sh-u-separator--small"></div>

    <div class="grid">
      <div class="md:col-6">
        <sh-form-label for="username" required="true" i18n
          >Benutzername</sh-form-label
        >
        <input
          pInputText
          class="sh-u-full-width"
          type="text"
          id="username"
          formControlName="username"
        />
        <sh-form-errors [for]="userForm.controls.username"></sh-form-errors>
      </div>

      <div class="md:col-6">
        <sh-form-label for="email" required="true" i18n
          >E-Mail Adresse</sh-form-label
        >
        <input
          pInputText
          class="sh-u-full-width"
          type="email"
          id="email"
          formControlName="email"
        />
        <sh-form-errors [for]="userForm.get('email')"></sh-form-errors>
      </div>

      <div class="md:col-6">
        <sh-form-label for="password" required="true" i18n
          >Passwort</sh-form-label
        >
        <input
          pInputText
          class="sh-u-full-width"
          type="password"
          id="password"
          formControlName="password"
        />
        <sh-form-errors [for]="userForm.get('password')"></sh-form-errors>
        <sh-custom-errors
          errors="Passwörter stimmen nicht überein"
          i18n-errors
          [isVisible]="userForm.errors?.passwordsDontMatch"
        ></sh-custom-errors>
        <span class="sh-u-help-block" i18n
          >Passwort muss aus mind. 8 Zeichen bestehen, davon 1 Sonderzeichen, 1
          Ziffer und 1 Großbuchstabe, keine Leerzeichen</span
        >
      </div>
      <div class="md:col-6">
        <sh-form-label for="password2" required="true" i18n
          >Passwort wiederholen</sh-form-label
        >
        <input
          pInputText
          class="sh-u-full-width"
          type="password"
          id="password2"
          formControlName="passwordrepeat"
        />
        <sh-form-errors [for]="userForm.get('passwordrepeat')"></sh-form-errors>
      </div>
    </div>

    <div class="sh-u-separator--small"></div>

    <p i18n>
      Mit Ihrer Anmeldung erklären Sie sich mit unseren
      <a target="_blank" href="../../../../assets/Nutzungsbedingungen.pdf"
        >Nutzungsbedingungen</a
      >
      einverstanden.<br />Bitte lesen Sie unseren Hinweis zu Cookies.
    </p>
    <p-button
      type="submit"
      label="TOP-TEAM Account erstellen"
      i18n-label
    ></p-button>

    <div class="sh-u-separator--small"></div>

    <span i18n>Sie besitzen bereits einen Account? </span>
    <a routerLink="/login" i18n>Zum Login</a>

    <div class="sh-u-separator--small"></div>
  </div>
</form>
