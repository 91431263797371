<div class="su-u-overlay">
  <div class="row">
    <h1>
      <img
        src="libs/global-shared/shared-assets/img/top-team-zentraleinkaufs-gmbh.svg"
      /><br />
      <span i18n>Lieferantenportal</span>
    </h1>
    <div class="md:col-offset-4 md:col-4 md:col-offset-4 well">
      <h2 i18n>Gleichzeitiger Login mit mehreren Benutzern</h2>
      <p>
        <span i18n>Sie versuchen, sich gleichzeitig mit Benutzername </span>
        <b i18n>{{ loggedInUserChanged.previousUserName }}</b>
        <span i18n> und </span>
        <b i18n>{{ loggedInUserChanged.currentUserName }}</b>
        <span i18n>anzumelden.</span>
        <br />
        <span i18n
          >Gleichzeitiger Login mit mehreren Benutzern wird aus
          Sicherheitsgründen nicht unterstützt.</span
        >
        <br />
        <span i18n
          >Bitte schließen Sie diesen Tab, oder laden Sie ihn neu.</span
        >
        <br />
      </p>
    </div>
  </div>
</div>
