import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'foodLabelingLabel',
})
export class FoodLabelingLabelPipe implements PipeTransform {
  transform(foodLabelingLabelKey: string): string {
    let foodLabelingLabelValue = '';
    switch (foodLabelingLabelKey) {
      case 'FAIRTRADE':
        foodLabelingLabelValue = $localize`Fair Trade`;
        break;
      case 'GLUTENFREE':
        foodLabelingLabelValue = $localize`Glutenfrei`;
        break;
      case 'GMOFREE':
        foodLabelingLabelValue = $localize`GMOFREE`;
        break;
      case 'HALAL':
        foodLabelingLabelValue = $localize`Halal`;
        break;
      case 'KOSHER':
        foodLabelingLabelValue = $localize`Koscher`;
        break;
      case 'LACTOSEFREE':
        foodLabelingLabelValue = $localize`Laktosefrei`;
        break;
      case 'ORGANIC':
        foodLabelingLabelValue = $localize`Bio`;
        break;
      case 'SUGARFREE':
        foodLabelingLabelValue = $localize`Zuckerfrei`;
        break;
      case 'VEGAN':
        foodLabelingLabelValue = $localize`Vegan`;
        break;
    }
    return foodLabelingLabelValue;
  }
}
