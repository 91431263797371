/* eslint-disable @typescript-eslint/no-explicit-any */

import { isEmptyOrUndefined } from './string-util';

declare global {
  interface Array<T> {
    toKeyedObject(keySelector: (item: T) => string): { [key: string]: T };

    toIndexedObject(indexSelector: (item: T) => number | keyof T): {
      [index: number]: T;
    };

    last(): T | undefined;

    clear(): void;
  }

  interface ObjectConstructor {
    values(o: any): any[];

    entries(o: any): [string, any][];
  }
}

/**
 * Checks if the given object is null or undefined
 * @param value
 */
export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function deepCopy<T>(source: T): T {
  return JSON.parse(JSON.stringify(source));
}

export function addObjectPolyfills(): void {
  if (Object.values && Object.entries) {
    return;
  }

  const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
  const isEnumerable = Function.bind.call(
    Function.call,
    Object.prototype.propertyIsEnumerable
  );
  const concat = Function.bind.call(Function.call, Array.prototype.concat);
  const keys = Reflect.ownKeys;

  /**
   * Adds missing functionality of ES Object API for "some browsers".
   */

  if (!Object.values) {
    Object.values = (o: any) =>
      reduce(
        keys(o),
        (v: any, k: any) => {
          return concat(
            v,
            typeof k === 'string' && isEnumerable(o, k) ? [o[k]] : []
          );
        },
        []
      );
  }

  if (!Object.entries) {
    Object.entries = (o: any) =>
      reduce(
        keys(o),
        (e: any, k: any) => {
          return concat(
            e,
            typeof k === 'string' && isEnumerable(o, k) ? [[k, o[k]]] : []
          );
        },
        []
      );
  }
}

Array.prototype.toKeyedObject = function <T>(
  this: T[],
  keySelector: (item: T) => string
): { [key: string]: T } {
  return this.reduce((keyedObj, item) => {
    keyedObj[keySelector(item)] = item;
    return keyedObj;
  }, {} as { [key: string]: T });
};

Array.prototype.toIndexedObject = function <T>(
  this: T[],
  indexSelector: (item: T) => number
): { [index: number]: T } {
  return this.reduce((indexedObj, item) => {
    indexedObj[indexSelector(item)] = item;
    return indexedObj;
  }, {} as { [key: number]: T });
};

Array.prototype.last = function <T>(this: T[]): T | undefined {
  return this.length > 0 ? this[this.length - 1] : undefined;
};

Array.prototype.clear = function <T>(this: T[]): void {
  while (this.length > 0) {
    this.pop();
  }
};

export function isEmptyObject(obj: any): boolean {
  return Object.entries(obj).length === 0;
}

export function objectHasProperty(obj: any, property: string) {
  return Object.prototype.hasOwnProperty.call(obj, property);
}

export function isUndefinedOrEmptyObject(obj: any): boolean {
  return isNullOrUndefined(obj) || isEmptyObject(obj);
}

/**
 * Delete all properties from the given object that have either undefined, null or empty string as value.
 */
export function stripEmptyProperties(obj: any): any {
  if (!obj) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (isNullOrUndefined(value) || value === '') {
      delete obj[key];
    }
  });
  return obj;
}

/**
 * Convert an array of `boolean` to a scalar `boolean`, or `undefined` when indeterminate.
 * E.g.: `[true]` => `true`, `[false]` => `false`, `[true, false]` => `undefined`
 */
export function collapseBooleanArray(
  arr: boolean[] | undefined
): boolean | undefined {
  if (isNullOrUndefined(arr) || arr.length === 0) return undefined;
  const firstVal = arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] !== firstVal) return undefined;
  }
  return firstVal;
}

export function toNullWhenAllPropertiesBlank<T>(obj: T): T | null {
  return allPropertiesBlank(obj) ? null : obj;
}

export function allPropertiesBlank(obj: any | null | undefined): boolean {
  if (obj === null || obj === undefined) {
    return true;
  }

  return Object.values(obj).every((prop) =>
    isEmptyOrUndefined(prop?.toString())
  );
}
