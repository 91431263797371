import { RouterModule, Routes } from '@angular/router';
import { UserRegistrationComponent } from './user-registration/registration/user-registration.component';
import { AuthGuard } from './guards/auth.guard';
import { SupplierAreaGuard } from './guards/supplier-area.guard';
import { RegisterconfirmComponent } from './user-registration/registration/registerconfirm/registerconfirm.component';
import { PasswordForgottenComponent } from './user-registration/password-reset/password-forgotten/password-forgotten.component';
import { PasswordResetComponent } from './user-registration/password-reset/password-reset/password-reset.component';
import { RegistersuccessComponent } from './user-registration/registration/registersuccess/registersuccess.component';
import { environment } from '../environments/environment';
import { LogoutGuard } from './guards/logout-guard';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { SecuredLayoutComponent } from './layout/secured-layout/secured-layout.component';
import { LoginLandingComponent } from './login-landing/login-landing.component';
import { NgModule } from '@angular/core';
import { ResendEmailComponent } from './user-registration/resend-email/resend-email.component';
import { BankingInformationConfirmComponent } from './supplier-shared/payment/banking-information/banking-information-confirm.component';
import { SupplierRegistrationGuard } from './guards/supplier-registration.guard';

const appRoutes: Routes = [
  { path: '', redirectTo: 'supplier-area', pathMatch: 'full' },

  {
    path: 'login',
    component: LoginLandingComponent,
    canActivate: [LogoutGuard],
  },

  {
    // public pages with public layout
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'password-forgotten', component: PasswordForgottenComponent },
      { path: 'user-registration', component: UserRegistrationComponent },
      { path: 'registerform-success', component: RegistersuccessComponent },
      { path: 'registerconfirm/:token', component: RegisterconfirmComponent },
      { path: 'passwordreset/:token', component: PasswordResetComponent },
      {
        path: 'banking-info-confirm/:token',
        component: BankingInformationConfirmComponent,
      },
      { path: 'resend-email', component: ResendEmailComponent },
      {
        path: 'article-recall',
        loadChildren: () =>
          import(
            './anonymous-article-recall/anonymous-article-recall.module'
          ).then((m) => m.AnonymousArticleRecallModule),
      },
      {
        path: 'user-invitation',
        loadChildren: () =>
          import('./user-invitation/user-invitation.module').then(
            (m) => m.UserInvitationModule
          ),
      },
    ],
  },

  {
    // secured pages with secured layout
    path: '',
    component: SecuredLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'supplier-registration',
        canActivate: [SupplierRegistrationGuard],
        loadChildren: () =>
          import('./supplier-registration/supplier-registration.module').then(
            (m) => m.SupplierRegistrationModule
          ),
      },
      {
        path: 'supplier-area',
        canActivate: [SupplierAreaGuard],
        loadChildren: () =>
          import('./supplier-area/supplier-area.module').then(
            (m) => m.SupplierAreaModule
          ),
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, environment.routingSettings)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
