import { Injectable, OnDestroy } from '@angular/core';
import {
  combineLatest,
  EMPTY,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  map,
  skipWhile,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuthenticationContext } from '../authentication/authentication-context';
import { SupplierRegistrationStateService } from './supplier-registration-state.service';
import { SupplierStatus } from '@topteam-ui/global-shared/model/supplier/supplier-status.model';

const registrationTabsCount = 7;

@Injectable({
  providedIn: 'root',
})
export class RegistrationStateEventSource implements OnDestroy {
  private readonly asyncTabStatusSource$: { [index: number]: Subject<boolean> };
  private readonly supplierStateSource$ = new ReplaySubject<SupplierStatus>(1);
  private readonly subscriptions$ = new Subscription();
  private _isAllTabsFinished$: Observable<boolean>;

  constructor(
    private registrationStateService: SupplierRegistrationStateService,
    private authContext: AuthenticationContext
  ) {
    this.asyncTabStatusSource$ = {};
    for (let tabNr = 1; tabNr <= registrationTabsCount; tabNr++) {
      // Use BehaviorSubject to ensure new subscribers always get the most recent value.
      // See http://reactivex.io/rxjs/manual/overview.html#behaviorsubject
      this.asyncTabStatusSource$[tabNr] = new ReplaySubject<boolean>(1);
      this.subscriptions$.add(this.asyncTabStatusSource$[tabNr]); // for cleanup in ngOnDestroy()
    }
    this.subscriptions$.add(
      this.authContext.isLoggedIn$
        .pipe(
          skipWhile((isLoggedIn) => !isLoggedIn),
          switchMap(() => this.refresh())
        )
        .subscribe()
    );
  }

  public get supplierState$(): Observable<SupplierStatus> {
    return this.supplierStateSource$;
  }

  public refresh(): Observable<null> {
    return this.authContext.isLoggedIn$.pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.registrationStateService.getRegistrationState().pipe(
            tap((val) => {
              this.asyncTabStatusSource$[1].next(val.tabStates.TAB1);
              this.asyncTabStatusSource$[2].next(val.tabStates.TAB2);
              this.asyncTabStatusSource$[3].next(val.tabStates.TAB3);
              this.asyncTabStatusSource$[4].next(val.tabStates.TAB4);
              this.asyncTabStatusSource$[5].next(val.tabStates.TAB5);
              this.asyncTabStatusSource$[6].next(val.tabStates.TAB6);
              this.asyncTabStatusSource$[7].next(val.tabStates.TAB7);
              this.supplierStateSource$.next(val.supplierStatus);
            }),
            catchError((error) => {
              console.error(error);
              return EMPTY;
            }),
            map(() => undefined as never)
          );
        } else {
          Object.values(this.asyncTabStatusSource$).forEach((tabState$) =>
            tabState$.next(undefined)
          );
          this.supplierStateSource$.next(undefined);
          return EMPTY;
        }
      }),
      take(1)
    );
  }

  public setTabFinished(idx: number): void {
    this.asyncTabStatusSource$[idx].next(true);
  }

  /**
   * @return an Observable that keeps yielding a boolean value indicating whether the requested form tab has been completed
   * by the user.
   *
   * Use this to subscribe to status changes. To get only the most recent value, use {@link isTabFinished}.
   */
  public isTabFinished$(idx: number): Observable<boolean> {
    return this.asyncTabStatusSource$[idx];
  }

  /**
   * @return an Observable that keeps yielding a boolean value indicating whether all registration form tabs
   * have been completed by the user.
   *
   * Use this to subscribe to status changes. To get only the most recent value, use {@link isAllTabsFinished}.
   */
  public isAllTabsFinished$(): Observable<boolean> {
    return (
      this._isAllTabsFinished$ ||
      (this._isAllTabsFinished$ = combineLatest(
        Object.values(this.asyncTabStatusSource$)
      ).pipe(
        map((isAllFinished: boolean[]) =>
          isAllFinished.every((isFinished) => isFinished)
        )
      ))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
