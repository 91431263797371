import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'pt-three-state-choice',
  templateUrl: './three-state-choice.component.html',
  styleUrls: ['./three-state-choice.component.scss'],
})
export class ThreeStateChoiceComponent {
  @Input() radioGroupName: string;
  @Input() choiceFormControl: UntypedFormControl;
}
