import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { Router } from '@angular/router';
import { isEmptyOrUndefined } from '@topteam-ui/global-shared/util/string-util';
import { catchError, switchMap } from 'rxjs/operators';
import { EMPTY, from, Subscription } from 'rxjs';

@Component({
  selector: 'pt-login-landing',
  templateUrl: './login-landing.component.html',
  styleUrls: ['./login-landing.component.scss'],
})
export class LoginLandingComponent implements OnDestroy {
  username: string;
  password: string;

  invalidCredentials = false;
  private subscriptions$ = new Subscription();

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  authenticateUser(): void {
    if (
      isEmptyOrUndefined(this.username) ||
      isEmptyOrUndefined(this.password)
    ) {
      this.invalidCredentials = true;
      return;
    }

    this.subscriptions$.add(
      this.authenticationService
        .authenticateUser(this.username, this.password)
        .pipe(
          switchMap((success) => {
            if (!success) {
              this.invalidCredentials = true;
              return EMPTY;
            }
            return from(this.router.navigate(['supplier-area']));
          }),
          catchError(() => {
            this.invalidCredentials = true;
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
