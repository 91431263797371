import { Inject, Injectable, InjectionToken, LOCALE_ID } from '@angular/core';
import { isEmptyOrUndefined, trimFirstToken } from './string-util';

const apiPrefixSegments = ['api'];

@Injectable({
  providedIn: 'root',
})
export class UrlHelperService {
  readonly #languageCode: string;
  readonly #appBaseHref: string;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    @Inject(TT_APP_BASE_HREF) appBaseHref: string
  ) {
    this.#languageCode = locale.substring(0, 2).toLowerCase();
    this.#appBaseHref = appBaseHref;
  }

  prepareApiPath(...segments: string[]): string {
    const baseUrl = this.getNonLocalizedBasePath();
    const prefixedSegments = [baseUrl, ...apiPrefixSegments, ...segments];
    return `/${prefixedSegments
      .map((segment) => this.trimFirstSlashIfPresent(segment))
      .filter((segment) => segment.length > 0)
      .join('/')}`;
  }

  getNonLocalizedBasePath(): string {
    return this.#appBaseHref
      .replace(/\/$/, '')
      .replace(new RegExp(`/${this.#languageCode}/?$`), '');
  }

  private trimFirstSlashIfPresent(urlSegment: string): string {
    return isEmptyOrUndefined(urlSegment)
      ? urlSegment
      : trimFirstToken(urlSegment, '/');
  }
}

const TT_APP_BASE_HREF = new InjectionToken<string>('', {
  providedIn: 'root',
  factory: () =>
    document.querySelector('head base')?.attributes?.getNamedItem('href')
      ?.value ?? '',
});
