<sh-card headerText="Bankdaten bearbeiten" i18n-headerText>
  <sh-info-text
    *ngIf="success$ | async"
    severity="success"
    text="Vielen Dank. Die Änderung Ihrer Bankdaten wurde zur Prüfung an TOP-TEAM Zentraleinkauf weitergeleitet"
    i18n-text
  ></sh-info-text>

  <sh-info-text
    *ngIf="(success$ | async) !== true"
    severity="error"
    text="Ihr Link hat seine Gültigkeit verloren"
    i18n-text
  ></sh-info-text>

  <div class="col-12 button-bar">
    <p-button label="zurück" i18n-label [routerLink]="['/']"></p-button>
  </div>
</sh-card>
