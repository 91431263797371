import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UrlHelperService } from '@topteam-ui/global-shared/util/url-helper.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetService {
  constructor(private http: HttpClient, private urlHelper: UrlHelperService) {}

  public requestPasswordResetToken(username: string): Observable<void> {
    return this.http.put<void>(
      this.urlHelper.prepareApiPath(
        'passwordreset',
        `/token/request?username=${encodeURI(username)}`
      ),
      undefined
    );
  }

  public checkTokenValid(token: string): Observable<void> {
    return this.http.get<void>(
      this.urlHelper.prepareApiPath('passwordreset', `/token/check/${token}`)
    );
  }

  public updatePassword(
    token: string,
    password: string
  ): Observable<UpdatePasswordResult> {
    return this.http
      .post(
        this.urlHelper.prepareApiPath('passwordreset'),
        { token, password },
        { observe: 'response' }
      )
      .pipe(
        map(() => ({ success: true } as UpdatePasswordResult)),
        catchError((error) => {
          if (error.status === StatusCodes.BAD_REQUEST) {
            return of({
              success: false,
              error: 'OLD_PASSWORD_EQUALS_NEW_PASSWORD',
            } as UpdatePasswordResult);
          }
          return of({
            success: false,
            error: 'UNKNOWN',
          } as UpdatePasswordResult);
        })
      );
  }
}

export type UpdatePasswordResult =
  | { success: true }
  | { success: false; error: UpdatePasswordError };

export type UpdatePasswordError =
  | 'OLD_PASSWORD_EQUALS_NEW_PASSWORD'
  | 'UNKNOWN';
