import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValueFromMap',
})
export class GetValueFromMapPipe implements PipeTransform {
  transform<T, K>(map: ReadonlyMap<T, K[]>, key: T): K[] {
    return map.get(key);
  }
}
