import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beerTaxValue',
})
export class BeerTaxValuePipe implements PipeTransform {
  transform(beerTaxKey: string): string {
    let beerTaxValue = '';
    switch (beerTaxKey) {
      case 'GRAVITY_04':
        beerTaxValue = '4° Stammwürze';
        break;
      case 'GRAVITY_05':
        beerTaxValue = '5° Stammwürze';
        break;
      case 'GRAVITY_06':
        beerTaxValue = '6° Stammwürze';
        break;
      case 'GRAVITY_07':
        beerTaxValue = '7° Stammwürze';
        break;
      case 'GRAVITY_08':
        beerTaxValue = '8° Stammwürze';
        break;
      case 'GRAVITY_09':
        beerTaxValue = '9° Stammwürze';
        break;
      case 'GRAVITY_10':
        beerTaxValue = '10° Stammwürze';
        break;
      case 'GRAVITY_11':
        beerTaxValue = '11° Stammwürze';
        break;
      case 'GRAVITY_12':
        beerTaxValue = '12° Stammwürze';
        break;
      case 'GRAVITY_13':
        beerTaxValue = '13° Stammwürze';
        break;
      case 'GRAVITY_15':
        beerTaxValue = '15° Stammwürze';
        break;
      case 'GRAVITY_16':
        beerTaxValue = '16° Stammwürze';
        break;
      case 'GRAVITY_17':
        beerTaxValue = '17° Stammwürze';
        break;
      case 'GRAVITY_18':
        beerTaxValue = '18° Stammwürze';
        break;
      case 'GRAVITY_19':
        beerTaxValue = '19° Stammwürze';
        break;
      case 'GRAVITY_23':
        beerTaxValue = '23° Stammwürze';
        break;
      case 'GRAVITY_28':
        beerTaxValue = '28° Stammwürze';
        break;
      default:
        beerTaxValue = '';
        break;
    }
    return beerTaxValue;
  }
}
