import { Component, OnDestroy, OnInit } from '@angular/core';
import { PasswordResetService } from '../../../services/password-reset/password-reset.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from '@topteam-ui/global-shared/services';
import { markAllFormControls } from '@topteam-ui/global-shared/util/reactive-form-util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pt-portal-passwordforgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss'],
})
export class PasswordForgottenComponent implements OnInit, OnDestroy {
  requestSuccessful = false;
  passwordReset: PasswordForgottenForm;

  #destroy$ = new Subject<void>();

  constructor(
    private passwordResetService: PasswordResetService,
    private formBuilder: FormBuilder,
    private toaster: ToastService
  ) {}

  ngOnInit(): void {
    this.passwordReset = this.buildForm();
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  sendPasswordForgottenMail(): void {
    if (!this.passwordReset.valid) {
      markAllFormControls(this.passwordReset);
      this.toaster.formErrorsToast();
      return;
    }

    const usrCtrl = this.passwordReset.get('username');

    this.passwordResetService
      .requestPasswordResetToken(usrCtrl.value)
      .pipe(takeUntil(this.#destroy$))
      .subscribe();

    // ignore error case for avoiding attack vectors
    this.requestSuccessful = true;
  }

  private readonly buildForm = () =>
    this.formBuilder.group({
      username: ['', Validators.required],
    });
}

type PasswordForgottenForm = ReturnType<
  PasswordForgottenComponent['buildForm']
>;
