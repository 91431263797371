import { isNullOrUndefined } from './object-util';

declare global {
  interface String {
    padStart(targetLength: number, padString?: string): string;
  }
}

export function isEmptyOrUndefined(value: string): boolean {
  return isNullOrUndefined(value) || value.length <= 0;
}

/**
 * Trims first occurrence of the given token in the given source string.
 *
 * @param source
 * @param token
 */
export function trimFirstToken(source: string, token: string): string {
  return source.replace(token, '');
}

/**
 * Concatenates the given value using the given token to the source string if the value is present. Does nothing
 * otherwise.
 *
 * @param source
 * @param token
 * @param value
 */
export function concatValueIfPresent(
  source: string,
  token: string,
  value: string
): string {
  let str = source;
  if (!isEmptyOrUndefined(value)) {
    str += token += value;
  }
  return str;
}

export function addStringPolyfills(): void {
  // https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
  if (!String.prototype.padStart) {
    String.prototype.padStart = function (
      targetLength: number,
      padString?: string
    ): string {
      targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
      padString = String(typeof padString !== 'undefined' ? padString : ' ');
      if (this.length >= targetLength) {
        return String(this);
      } else {
        targetLength = targetLength - this.length;
        if (targetLength > padString.length) {
          padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
        }
        return padString.slice(0, targetLength) + String(this);
      }
    };
  }
}

export function parseIntIfPresent(
  value: string | undefined,
  radix = 10
): number | undefined {
  if (isNullOrUndefined(value)) {
    return undefined;
  }
  return parseInt(value, radix);
}
