<div class="grid">
  <div class="col-12">
    <sh-form-label i18n for="firstname" required="true">Vorname</sh-form-label>
    <input
      pInputText
      name="firstname"
      class="sh-u-full-width"
      [formControl]="formGroup.get('firstname')"
    />
    <sh-form-errors [for]="formGroup.get('firstname')"></sh-form-errors>
  </div>
  <div class="col-12">
    <sh-form-label i18n for="lastname" required="true">Nachname</sh-form-label>
    <input
      pInputText
      name="lastname"
      class="sh-u-full-width"
      [formControl]="formGroup.get('lastname')"
    />
    <sh-form-errors [for]="formGroup.get('lastname')"></sh-form-errors>
  </div>
  <div class="md:col-12">
    <sh-form-label i18n required="true">Telefon</sh-form-label>
    <sh-custom-errors
      [isVisible]="showPhoneErrors"
      errors="Mind. eine Telefonnummer ist erforderlich."
      i18n-errors
    ></sh-custom-errors>
  </div>
  <div class="pt-phone-padding col-12">
    <sh-form-label i18n for="landline">Festnetz</sh-form-label>
    <input
      pInputText
      name="landline"
      i18n-placeholder
      placeholder="0043 7229 111 111"
      class="sh-u-full-width"
      [formControl]="formGroup.get('landline')"
    />
    <sh-form-errors [for]="formGroup.get('landline')"></sh-form-errors>
  </div>
  <div class="pt-phone-padding col-12">
    <sh-form-label i18n for="mobile">Mobil</sh-form-label>
    <input
      pInputText
      name="mobile"
      placeholder="0043 664 1111 111"
      class="sh-u-full-width"
      [formControl]="formGroup.get('mobile')"
    />
    <sh-form-errors [for]="formGroup.get('mobile')"></sh-form-errors>
  </div>
  <div class="col-12">
    <sh-form-label i18n for="email" required="true"
      >E-Mail Adresse</sh-form-label
    >
    <input
      pInputText
      name="email"
      class="sh-u-full-width"
      [formControl]="formGroup.get('email')"
    />
    <sh-form-errors [for]="formGroup.get('email')"></sh-form-errors>
  </div>
</div>
