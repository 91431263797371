import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nutrientAccuracy',
})
export class NutrientAccuracyPipe implements PipeTransform {
  transform(nutrientAccuracyKey: string): string {
    switch (nutrientAccuracyKey) {
      case 'APPROX':
        return $localize`ungefähr (~)`;
      case 'EXACT':
        return $localize`genau (=)`;
      case 'INFO':
        return $localize`Angabe der Messgenauigkeit der Werte in 100ml oder 100g (gleich / kleiner als / ungefähr)`;
      case 'LESSTHAN':
        return $localize`kleiner als (<)`;
      default:
        return '-';
    }
  }
}

/**
 *
 </ng-container>
 */
