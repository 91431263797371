import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEmptyOrUndefined } from '../../util/string-util';
import { isNullOrUndefined } from '../../util/object-util';
import { CalendarLocalizationService } from '../../util/calendar-localization.service';

/**
 * Thin adapter for PrimeNG Calendar. Exposes the value in serialized format that is appropriate to the API
 * (i.e. ISO 8601).
 */
@Component({
  selector: 'sh-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor {
  @ViewChild('calendar', { static: true }) calendar: Calendar;
  @Input() appendTo: unknown;
  @Input() inputId: string | undefined;

  readonly locale = this.localizationService.getLang();

  constructor(private localizationService: CalendarLocalizationService) {}

  registerOnChange(fn: (date: string) => void): void {
    this.calendar.registerOnChange((date: Date) => {
      if (isNullOrUndefined(date)) {
        fn(undefined);
        return;
      }
      fn(date.toJSON());
    });
  }

  writeValue(value: string): void {
    if (isEmptyOrUndefined(value)) {
      this.calendar.writeValue(undefined);
      return;
    }
    this.calendar.writeValue(new Date(value));
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: Function): void {
    this.calendar.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.calendar.setDisabledState(isDisabled);
  }
}
