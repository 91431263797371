<div class="card">
  <h2 class="sh-h2" i18n>Passwort zurücksetzen</h2>

  <div *ngIf="state === PasswordResetState.TOKEN_INVALID">
    <pt-big-icon-text severity="error">
      <span i18n
        >Ihre Anfrage zum Ändern des Passwortes ist leider nicht mehr
        gültig.</span
      >

      <div class="sh-u-separator--small"></div>

      <a routerLink="/login">
        <p-button label="Login" i18n-label></p-button>
      </a>
    </pt-big-icon-text>
  </div>

  <div *ngIf="state === PasswordResetState.PENDING">
    <p i18n>Bitte geben Sie Ihr neues Passwort ein.</p>
    <form [formGroup]="passwordReset" (ngSubmit)="onSubmitPasswordReset()">
      <div class="grid">
        <div class="md:col-6">
          <sh-form-label for="password1" required="true" i18n
            >neues Passwort</sh-form-label
          >
          <input
            pInputText
            type="password"
            id="password1"
            formControlName="password1"
            class="sh-u-full-width"
          />
          <span i18n class="sh-u-help-block"
            >Passwort muss aus mind. 8 Zeichen bestehen, davon 1 Sonderzeichen,
            1 Ziffer und 1 Großbuchstabe, keine Leerzeichen</span
          >
          <sh-form-errors
            [for]="passwordReset.get('password1')"
          ></sh-form-errors>
          <sh-custom-errors
            errors="Passwörter stimmen nicht überein"
            i18n-errors
            [isVisible]="passwordReset.errors?.passwordsDontMatch"
          ></sh-custom-errors>
        </div>
        <div class="md:col-6">
          <sh-form-label for="password2" required="true" i18n
            >Passwort wiederholen</sh-form-label
          >
          <input
            pInputText
            type="password"
            id="password2"
            formControlName="password2"
            class="sh-u-full-width"
          />
          <sh-form-errors
            [for]="passwordReset.get('password2')"
          ></sh-form-errors>
          <sh-custom-errors
            errors="Passwörter stimmen nicht überein"
            i18n-errors
            [isVisible]="passwordReset.errors?.passwordsDontMatch"
          ></sh-custom-errors>
        </div>
      </div>

      <div class="sh-u-separator--small"></div>
      <p-button type="submit" label="Speichern" i18n-label></p-button>
    </form>
  </div>

  <div *ngIf="state === PasswordResetState.SUCCESSFUL">
    <pt-big-icon-text>
      <span i18n>Ihr Passwort wurde erfolgreich geändert.</span>

      <div class="sh-u-separator--small"></div>

      <a routerLink="/login">
        <p-button label="Login" i18n-label></p-button>
      </a>
    </pt-big-icon-text>
  </div>

  <div class="sh-u-separator--small"></div>
</div>
