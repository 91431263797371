<div class="layout-topbar" *ngIf="userLoggedIn()">
  <ul class="topbar-menu fadeInDown">
    <li>
      <a (click)="logout()" class="sh-u-hover-pointer">
        <i class="topbar-icon pi pi-power-off"></i>
      </a>
    </li>

    <li
      class="po-header__user-profile"
      #profile
      [ngClass]="{
        'active-topmenuitem': topbarState.activeTopbarItem === profile
      }"
    >
      <div class="po-header__user-name">
        <span>{{ userInfo$ | async }} {{ supplierName$ | async }}</span>
      </div>
      <a
        (click)="topbarState.onTopbarItemClick($event, profile)"
        class="sh-u-hover-pointer"
      >
        <i class="pi pi-user topbar-icon"></i>
      </a>

      <ul class="fadeInDown">
        <li role="menuitem">
          <a [routerLink]="['/user-management/profile']">
            <i class="pi pi-user"></i>
            <span i18n>Profil</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <ul class="po-header__top-menu" *ngIf="isSupplierActive$ | async">
    <li class="po-header__top-menu__item">
      <a
        class="po-header__top-menu__item__link"
        [routerLink]="['/supplier-area/misc/business-analysis']"
        i18n
      >
        BUSINESS ANALYSEN
      </a>
    </li>
    <li class="po-header__top-menu__item">
      <a
        class="po-header__top-menu__item__link"
        [routerLink]="['/supplier-area/misc/flyer']"
        i18n
      >
        FLUGBLÄTTER
      </a>
    </li>
  </ul>
</div>

<div *ngIf="!userLoggedIn()">
  <pt-public-header></pt-public-header>
</div>
