import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LogoutGuard {
  constructor(private authService: AuthenticationService) {}

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      switchMap((isAuthenticated) =>
        isAuthenticated ? this.authService.logoutUser() : of(undefined)
      ),
      map(() => true),
      catchError(() => of(true))
    );
  }
}
