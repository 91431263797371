<div class="card">
  <h2 class="sh-h2" i18n>Passwort vergessen?</h2>
  <p i18n>
    Bitte geben Sie Ihren Benutzernamen ein, damit Sie ihr Passwort zurücksetzen
    können. <br />
    Sie erhalten im Anschluss eine E-Mail mit einem Bestätigungslink von uns.
  </p>

  <div class="sh-u-separator--small"></div>

  <form [formGroup]="passwordReset" (ngSubmit)="sendPasswordForgottenMail()">
    <sh-form-label for="name" required="true" i18n>
      Benutzername
    </sh-form-label>
    <input
      pInputText
      name="username"
      type="text"
      id="name"
      formControlName="username"
      class="reset__input"
    />
    <sh-form-errors [for]="passwordReset.get('username')"></sh-form-errors>

    <div class="email-sent" [hidden]="!requestSuccessful">
      <i class="pi pi-check email-sent__icon"></i
      ><span i18n class="email-sent__text">Email wurde versendet.</span>
    </div>

    <div class="sh-u-separator--small"></div>
    <p-button
      type="submit"
      label="Email zum Passwort-Zurücksetzen anfordern"
      i18n-label
    ></p-button>
    <div class="sh-u-separator--small"></div>
  </form>
</div>
