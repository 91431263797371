<div
  class="sh-info-box"
  [ngClass]="'sh-info-box--' + severity"
  [class.sh-u-full-height]="fullHeight"
>
  <i
    class="sh-info-box__icon pi {{ iconClass || '' }}"
    [class.pi-info-circle]="!iconClass && severity === 'info'"
    [class.pi-exclamation-triangle]="!iconClass && severity === 'warn'"
    [class.pi-times]="!iconClass && severity === 'error'"
    [class.pi-check]="!iconClass && severity === 'success'"
  ></i>
  <div class="sh-info-box__text">
    <span *ngIf="text; else content" [innerHTML]="text"></span>
    <ng-template #content
      ><span class="sh-info-box__text"><ng-content></ng-content></span
    ></ng-template>
  </div>
</div>
