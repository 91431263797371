<div class="sh-yes-no-switch">
  <div class="sh-yes-no-switch__wrapper">
    <label class="sh-yes-no-switch__label" (click)="noClicked()" i18n
      >Nein</label
    >
    <div class="sh-yes-no-switch__p-input-switch-wrapper">
      <p-inputSwitch
        [(ngModel)]="value"
        (ngModelChange)="propagateChange(value)"
      ></p-inputSwitch>
    </div>
    <label class="sh-yes-no-switch__label" (click)="yesClicked()" i18n
      >Ja</label
    >
  </div>
</div>
