import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'articleRecallBatchIdentificationStrategy',
})
export class ArticleRecallBatchIdentificationStrategyPipe
  implements PipeTransform
{
  transform(identificationStrategyKey: string): string {
    let identificationStrategyValue = '';
    switch (identificationStrategyKey) {
      case 'ALL':
        identificationStrategyValue = $localize`:@@article-recall--batches-identification-strategy-all:Alle Chargen betroffen`;
        break;
      case 'BY_NUMBER':
        identificationStrategyValue = $localize`:@@article-recall--batches-identification-strategy-by-number:Chargennummer`;
        break;
      case 'BY_BEST_BEFORE_END':
        identificationStrategyValue = $localize`:@@article-recall--batches-identification-strategy-by-bbe:MHD ist gleich Chargennummer`;
        break;
    }
    return identificationStrategyValue;
  }
}
