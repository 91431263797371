import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopteamFooterComponent } from './components/topteam-footer/topteam-footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RouterModule } from '@angular/router';
import { FormLabelComponent } from './components/form-label/form-label.component';
import { YesNoSwitchComponent } from './components/yes-no-switch/yes-no-switch.component';
import { CardComponent } from './components/card/card.component';
import { DaysOfWeekPickerComponent } from './components/days-of-weeks-picker/days-of-week-picker.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { FileDownloadComponent } from './components/file-download/file-download.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { CustomFormErrorsComponent } from './components/custom-form-errors/custom-form-errors.component';
import { InfoTextComponent } from './components/info-text/info-text.component';
import { AttentionTextComponent } from './components/attention-text/attention-text.component';
import { PhoneNumberComponent } from './components/phone-number-input/phone-number.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContentNegotiationInterceptor } from './interceptors/content-negotiation-interceptor';
import { AcceptLanguageInterceptor } from './interceptors/accept-language-interceptor';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { ReadOnlyRowComponent } from './components/read-only-row/read-only-row.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { TimeOfDayPickerComponent } from './components/time-of-day-picker/time-of-day-picker.component';
import { AppMenuModule } from './components/app-menu/app-menu.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FileUploadModule } from 'primeng/fileupload';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MessageModule } from 'primeng/message';
import { NumberInputComponent } from './components/number-input-component/number-input.component';
import { InputTextModule } from 'primeng/inputtext';
import { OrPlaceholderPipe } from './pipes/or-placeholder.pipe';
import { PluckPipe } from './pipes/pluck.pipe';
import { PluckFormControlPipe } from './pipes/pluck-form-control.pipe';
import { PluckForGroupPipePipe } from './pipes/pluck-form-group.pipe';
import { ToObservablePipe } from './pipes/to-observable.pipe';
import { GetFormControlPipe } from './pipes/get-form-control.pipe';
import { HasPipe } from './pipes/has.pipe';
import { GetFormGroupPipe } from './pipes/get-form-group.pipe';
import { UpperSnakeCasePipe } from './pipes/upper-snake-case.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { ToConsolePipe } from './pipes/to-console.pipe';
import { GetValuePipe } from './pipes/get-value.pipe';
import { DataStreamPipe } from './pipes/data-stream-values.pipe';
import { UnitPipe } from './pipes/unit.pipe';
import { BaseUnitValuePipe } from './pipes/base-unit-value.pipe';
import { FoodLabelingLabelPipe } from './pipes/food-labeling-label.pipe';
import { CertificateLabelPipe } from './pipes/certificate-label.pipe';
import { ArticleRecallBatchIdentificationStrategyPipe } from './pipes/article-recall-batch-identification-strategy.pipe';
import { AllergenValuePipe } from './pipes/allergen-value.pipe';
import { NutrientValuePipe } from './pipes/nutrient-value.pipe';
import { IncotermsValuePipe } from './pipes/incoterms-value.pipe';
import { UserPositionPipe } from './pipes/user-position.pipe';
import { TaxValuePipe } from './pipes/tax-value.pipe';
import { BeerTaxValuePipe } from './pipes/beer-tax-value.pipe';
import { NutrientAccuracyPipe } from './pipes/nutrient-accuracy.pipe';
import { MeasuringAccuracyValuePipe } from './pipes/measuring-accuracy-value.pipe';
import { SalutationPipe } from './pipes/salutation.pipe';
import { CertificateTypePipe } from './pipes/certificate-type.pipe';
import { OrderTypePipe } from './pipes/order-type.pipe';
import { NotExistsPipe } from './pipes/not-exists.pipe';
import { GetValueFromMapPipe } from './pipes/get-value-from-map.pipe';
import { ResourcePathPipe } from './pipes/resource-path.pipe';
import { BlankToNullDirective } from './components/blank-to-null/blank-to-null.directive';

const moduleExports: Type<unknown>[] = [
  TopteamFooterComponent,
  FormLabelComponent,
  ReadOnlyRowComponent,
  YesNoSwitchComponent,
  CardComponent,
  FormErrorsComponent,
  FileDownloadComponent,
  FileUploadComponent,
  CustomFormErrorsComponent,
  InfoTextComponent,
  AttentionTextComponent,
  DaysOfWeekPickerComponent,
  TimeOfDayPickerComponent,
  PhoneNumberComponent,
  DatePickerComponent,
  DateRangePickerComponent,
  YesNoPipe,
  NumberInputComponent,
  OrPlaceholderPipe,
  PluckPipe,
  PluckFormControlPipe,
  PluckForGroupPipePipe,
  ToObservablePipe,
  GetFormControlPipe,
  GetFormGroupPipe,
  HasPipe,
  UpperSnakeCasePipe,
  ToConsolePipe,
  DataStreamPipe,
  UnitPipe,
  BaseUnitValuePipe,
  FoodLabelingLabelPipe,
  CertificateLabelPipe,
  ArticleRecallBatchIdentificationStrategyPipe,
  AllergenValuePipe,
  NutrientValuePipe,
  IncotermsValuePipe,
  UserPositionPipe,
  TaxValuePipe,
  BeerTaxValuePipe,
  NutrientAccuracyPipe,
  MeasuringAccuracyValuePipe,
  SalutationPipe,
  CertificateTypePipe,
  OrderTypePipe,
  NotExistsPipe,
  GetValueFromMapPipe,
  ResourcePathPipe,
  BlankToNullDirective,
];

const sharedImports: Type<unknown>[] = [
  CommonModule,
  FormsModule,
  TooltipModule,
  CalendarModule,
  ConfirmDialogModule,
  RouterModule,
  InputSwitchModule,
  FileUploadModule,
  KeyFilterModule,
  SelectButtonModule,
  MessageModule,
  AppMenuModule,
  ReactiveFormsModule,
  InputTextModule,
];

@NgModule({
  imports: [...sharedImports],
  declarations: [
    ...moduleExports,
    ReadOnlyRowComponent,
    DateRangePickerComponent,
    KeysPipe,
    GetValuePipe,
    NotExistsPipe,
  ],
  exports: [...moduleExports, ...sharedImports, KeysPipe, GetValuePipe],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentNegotiationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      multi: true,
    },
  ],
})
export class GlobalSharedModule {}
