import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '../util/object-util';

/**
 * Transforms a `null` or `undefined` value to a placeholder string
 */
@Pipe({
  name: 'orPlaceholder',
  pure: true,
})
export class OrPlaceholderPipe implements PipeTransform {
  transform<T>(value: T, placeholder = '-'): NonNullable<T> | string {
    return isNullOrUndefined(value) ? placeholder : value;
  }
}
