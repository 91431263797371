<div class="card">
  <pt-big-icon-text [severity]="status ? 'success' : 'error'">
    <div *ngIf="status">
      <h2>Vielen Dank!</h2>
      <p i18n>
        Ihr Benutzerkonto wurde nun bei TOP-TEAM Zentraleinkauf GmbH
        eingerichtet. Sie können nach dem Log-In mit der Lieferantenbewerbung
        starten.
      </p>
    </div>

    <div *ngIf="!status">
      <h2 i18n>Aktivierung fehlgeschlagen.</h2>
      <p i18n>Ihr Link hat seine Gültigkeit verloren.</p>
      <p i18n>
        Bitte vergewissern Sie sich, dass Sie Ihr Benutzerkonto nicht bereits
        aktiviert haben oder ob Ihr Link den maximalen Zeitraum der Aktivierung
        bereits überschritten hat.
      </p>
      <a routerLink="/resend-email">
        <p-button i18n-label label="Mail erneut anfordern"></p-button>
      </a>
      <div class="sh-u-separator--small"></div>
    </div>

    <div *ngIf="status === undefined">
      <p i18n>Token wird überprüft</p>
    </div>

    <a routerLink="/login">
      <p-button label="Zum Login" i18n-label></p-button>
    </a>
  </pt-big-icon-text>
  <div class="sh-u-separator--small"></div>
</div>
