import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxValue',
})
export class TaxValuePipe implements PipeTransform {
  transform(taxValueKey: string): string {
    let taxValue = '';
    switch (taxValueKey) {
      case 'TAX_10':
        taxValue = $localize`10%`;
        break;
      case 'TAX_13':
        taxValue = $localize`13%`;
        break;
      case 'TAX_20':
        taxValue = $localize`20%`;
        break;
    }
    return taxValue;
  }
}
