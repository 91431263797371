import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from '../../util/object-util';
import { map, takeUntil } from 'rxjs/operators';

const thousandsSeparatorsRegex = /[',.]/;

@Component({
  selector: 'sh-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent implements ControlValueAccessor, OnDestroy {
  @Input() inputId = '';
  @Input() styleClass: string | string[] = '';
  readonly formControl = new UntypedFormControl();
  destroyed$ = new Subject<void>();
  propagateTouched = () => {
    /* no-op placeholder */
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  registerOnChange(fn: (value: string | number) => void): void {
    this.formControl.valueChanges
      .pipe(
        map((value) => this.normalize(value)),
        takeUntil(this.destroyed$)
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    this.formControl.setValue(value);
  }

  /**
   * @return input string parsed to float, or original input string if not parsable
   */
  normalize(value: string | number): string | number {
    if (isNullOrUndefined(value)) {
      return value;
    }

    if (typeof value === 'number') {
      return value;
    }

    const dotIndex = value.lastIndexOf('.');
    const commaIndex = value.lastIndexOf(',');
    const decimalSeparatorIndex = Math.max(dotIndex, commaIndex);

    if (decimalSeparatorIndex === -1) {
      return value.replace(thousandsSeparatorsRegex, '');
    }

    const preSeparator = value.substring(0, decimalSeparatorIndex);
    const postSeparator = value.substring(decimalSeparatorIndex + 1);
    return `${preSeparator.replace(
      thousandsSeparatorsRegex,
      ''
    )}.${postSeparator}`;
  }
}
