import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from '../model/article/article.model';

@Pipe({
  name: 'orderType',
})
export class OrderTypePipe implements PipeTransform {
  transform(orderTypeKey: OrderType): string {
    switch (orderTypeKey) {
      case 'CZFD':
        return $localize`Transgourmet Zentrallager FD`;
      case 'ZCC':
        return $localize`ZCC`;
      case 'ZSTA':
        return $localize`N&F Direkt`;
      case 'ZUNI':
        return $localize`Uni Direkt`;
      case 'CZDL':
        return $localize`tgOE Zentrallager TK-Linz`;
      case 'CZTK':
        return $localize`tgOE Zentrallager TK-Brummer`;
      case 'CCKL':
        return $localize`Transgourmet Klagenfurt`;
      default:
        return orderTypeKey;
    }
  }
}
