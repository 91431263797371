import { NgModule } from '@angular/core';
import { AppMenuComponent } from './app-menu.component';
import { AppMenuItemComponent } from './app-menu-item/app-menu-item.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [CommonModule, RouterModule, TooltipModule],
  declarations: [AppMenuComponent, AppMenuItemComponent],
  exports: [AppMenuComponent],
})
export class AppMenuModule {}
