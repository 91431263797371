import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
  private readonly language: string;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    this.language = locale.substring(0, 2).toLowerCase();
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const clonedRequest = req.clone({
      headers: req.headers.set('Accept-Language', this.language),
    });
    return next.handle(clonedRequest);
  }
}
