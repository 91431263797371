import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { deepCopy } from './object-util';
import { map } from 'rxjs/operators';
import { OperatorFunction } from 'rxjs';

/***
 * Recursively iterates through all form components and sets 'touched' and 'dirty' state.
 *
 * @param form
 */
export function markAllFormControls(form: AbstractControl): void {
  if (form.valid) {
    return;
  }
  internalDisplayFormErrors(form);
}

function internalDisplayFormErrors(control: AbstractControl): void {
  if (control instanceof UntypedFormGroup) {
    for (const childControl of Object.values(control.controls)) {
      internalDisplayFormErrors(childControl);
    }
  } else if (control instanceof UntypedFormArray) {
    control.controls.forEach((childControl) => {
      internalDisplayFormErrors(childControl);
    });
  } else {
    control.markAsTouched();
    control.markAsDirty();
  }
}

export function logErronousFormControls(
  ctrl: AbstractControl,
  name?: string
): void {
  name = !name ? '' : name;
  if (ctrl instanceof UntypedFormGroup) {
    for (const childCtrlName of Object.keys(ctrl.controls)) {
      const childCtrl = ctrl.controls[childCtrlName];
      logErronousFormControls(childCtrl, name + '.' + childCtrlName);
    }
  } else if (ctrl instanceof UntypedFormArray) {
    console.log('');
    console.log('Begin FormArray ' + name + ':');
    ctrl.controls.forEach((childCtrl) => {
      logErronousFormControls(childCtrl);
    });
    console.log('End FormArray ' + name + ':');
    console.log('');
  } else {
    if (ctrl.errors) {
      console.log(name + ':', ctrl.errors);
    }
  }
}

export function removeValidatorsFromControls(
  controls: AbstractControl[]
): void {
  controls.forEach((ctrl) => {
    ctrl.clearValidators();
    ctrl.reset();
  });
}

export function stripNonSelectedValues<T extends { selected?: boolean }>(
  values: T[],
  stripSelectedProperty = false
): T[] {
  let filteredValues = values.filter((value) => value['selected'] === true);
  if (stripSelectedProperty) {
    filteredValues = filteredValues.map((value) => {
      value = deepCopy(value);
      delete value['selected'];
      return value;
    });
  }
  return filteredValues;
}

/**
 * Strips out required-Validator from given FormControl and leaves other Validators in place
 *
 * @param predicate
 * @param formControl
 */
export function requiredIf(
  predicate: boolean,
  formControl: AbstractControl
): void {
  const existingErrors = formControl.errors;
  let requiredErrors: ValidationErrors; // undefined by default
  if (predicate) {
    requiredErrors = Validators.required(formControl);
  }

  let errors = { ...(existingErrors ?? {}) };

  if (requiredErrors) {
    errors = { ...existingErrors, ...requiredErrors };
  } else {
    delete errors.required;
  }

  errors = Object.keys(errors).length > 0 ? errors : null;

  formControl.setErrors(errors);
}

/**
 * @deprecated Should no longer be necessary once we transition to Typed Forms
 */
export function pluckControl(
  path: string | string[]
): OperatorFunction<UntypedFormGroup, UntypedFormControl | undefined>;
export function pluckControl(
  path: number[]
): OperatorFunction<UntypedFormArray, UntypedFormControl | undefined>;
/**
 * Use in pipe() on an Observable<FormArray> or Observable<FormGroup> to get an Observable of that control's child FormControl.
 * Convenience RxJS operator that can be reused in templates.
 *
 * @deprecated Should no longer be necessary once we transition to Typed Forms
 */
export function pluckControl(
  path: string | (string | number)[]
): OperatorFunction<AbstractControl, UntypedFormControl | undefined> {
  return map((control) => {
    const child = control.get(path);
    return child instanceof UntypedFormControl ? child : undefined;
  });
}

/**
 * @deprecated Should no longer be necessary once we transition to Typed Forms
 */
export function pluckGroup(
  path: string | string[]
): OperatorFunction<UntypedFormGroup, UntypedFormGroup | undefined>;
export function pluckGroup(
  path: number[]
): OperatorFunction<UntypedFormArray, UntypedFormGroup | undefined>;
/**
 * Use in pipe() on an Observable<FormArray> or Observable<FormGroup> to get an Observable of that control's child FormGroup.
 * Convenience RxJS operator that can be reused in templates.
 */
export function pluckGroup(
  path: string | (string | number)[]
): OperatorFunction<AbstractControl, UntypedFormGroup | undefined> {
  return map((control) => {
    const child = control.get(path);
    return child instanceof UntypedFormGroup ? child : undefined;
  });
}
