import { ValidatorFn } from '@angular/forms';
import { environment } from '../../environments/environment';

/**
 * Validates the username by specific regex and checks for invalid usernames and prefixes defined in `environment.ts`
 */
export const usernameValidator: ValidatorFn = (control) => {
  const value = control.value.toString();
  const reservedViolationError = {
    reservedUsernameViolation: {
      prefixes: environment.reservedUserNamePrefixes,
      usernames: environment.reservedUserNames,
    },
  };

  if (value) {
    const URL_REGEXP = /^([a-z0-9]){3,50}$/;

    if (!URL_REGEXP.test(value)) {
      return { usernameInvalid: {} };
    }

    for (const prefix of environment.reservedUserNamePrefixes) {
      if (value.startsWith(prefix)) {
        return reservedViolationError;
      }
    }

    for (const username of environment.reservedUserNames) {
      if (value === username) {
        return reservedViolationError;
      }
    }
  }

  return undefined;
};
