import { Pipe, PipeTransform } from '@angular/core';
import { UrlHelperService } from '../util/url-helper.service';

/**
 * Resolves a relative to an absolute path, relative to the language-independent application context path
 */
@Pipe({
  name: 'resourcePath',
  pure: true,
})
export class ResourcePathPipe implements PipeTransform {
  readonly #basePath: string;

  constructor(urlHelper: UrlHelperService) {
    const nonLocalizedBasePath = urlHelper.getNonLocalizedBasePath();
    this.#basePath = this.stripTrailingSlash(nonLocalizedBasePath);
  }

  transform(value: string): string {
    return this.#basePath + '/' + this.stripLeadingSlash(value);
  }

  private stripLeadingSlash(text: string): string {
    return text.replace(/^\//, '');
  }

  private stripTrailingSlash(text: string): string {
    return text.replace(/\/$/, '');
  }
}
