import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { AuthenticationContext } from './authentication-context';
import { undefinedWhenForbidden } from '@topteam-ui/global-shared/util/observable-util';
import { UrlHelperService } from '@topteam-ui/global-shared/util/url-helper.service';
import { UserInfo } from './user-info.model';
import { btoaUtf8 } from '@topteam-ui/global-shared/util/unicode-util';
import { CsrfInitializerService } from '@topteam-ui/global-shared/services/csrf/csrf-initializer.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userData: UserInfo | undefined = undefined;

  constructor(
    private http: HttpClient,
    private authContext: AuthenticationContext,
    private csrfInitializer: CsrfInitializerService,
    private urlHelper: UrlHelperService
  ) {}

  public authenticateUser(
    username: string,
    password: string
  ): Observable<boolean> {
    return this.http
      .post<UserInfo>(this.urlHelper.prepareApiPath('login'), undefined, {
        headers: this.createAuthorizationHeader(username, password),
      })
      .pipe(
        retry(2),
        this.updateUserDataOnResponse(),
        // Spring Security invalidates the CSRF token after login, so we need to obtain a new one
        switchMap(() => this.csrfInitializer.initializeCsrf()),
        map(() => true),
        catchError((err) => {
          console.error(err);
          return of(false);
        })
      );
  }

  public getLoggedInUser(): Observable<UserInfo> {
    if (this.userData !== undefined) {
      return of(this.userData);
    }

    return this.http
      .get<UserInfo>(this.urlHelper.prepareApiPath('me'))
      .pipe(this.updateUserDataOnResponse());
  }

  public isAuthenticated(): Observable<boolean> {
    if (this.userData !== undefined) {
      return of(true);
    }

    return this.getLoggedInUser().pipe(
      map((response) => response !== undefined),
      catchError(() => {
        this.resetLoggedInUserInfo();
        return of(false);
      })
    );
  }

  public logoutUser(): Observable<void> {
    // needs to be post request since it's required for csrf check
    return this.http
      .post<void>(this.urlHelper.prepareApiPath('logout'), undefined)
      .pipe(
        undefinedWhenForbidden(), // when backend returns 403 forbidden, we are already logged out and don't care
        tap(() => this.setLoggedInUserInfo(undefined)),
        switchMap(() => this.csrfInitializer.initializeCsrf()),
        catchError((err) => {
          console.error(err);
          return new Observable<void>();
        })
      );
  }

  private setLoggedInUserInfo(value: UserInfo): void {
    this.authContext.updateUserInformation(value);
    this.userData = value;
  }

  private resetLoggedInUserInfo(): void {
    this.setLoggedInUserInfo(undefined);
  }

  private createAuthorizationHeader(
    username: string,
    password: string
  ): HttpHeaders {
    let authHeaders = new HttpHeaders();
    authHeaders = authHeaders.append(
      'Authorization',
      'Basic ' + btoaUtf8(username + ':' + password)
    );
    return authHeaders;
  }

  private updateUserDataOnResponse(): MonoTypeOperatorFunction<UserInfo> {
    return tap({
      next: (userData) => this.setLoggedInUserInfo(userData),
      error: () => this.resetLoggedInUserInfo(),
    });
  }
}
