<div *ngIf="downloads?.length > 0" class="sh-file-download">
  <div *ngIf="!headerDisabled">
    <h3 *ngIf="header !== undefined; else noHeader">{{ header }}</h3>
    <ng-template #noHeader><h3 i18n>Hochgeladene Dateien</h3></ng-template>
  </div>
  <ul class="sh-file-download__list">
    <li *ngFor="let download of downloads" class="sh-file-download__list-item">
      <a
        [href]="download?.link | resourcePath"
        target="_blank"
        class="sh-file-download__link"
        >{{ download?.filename }}</a
      >
      <a
        href="javascript:void(0)"
        (click)="deleteFile(download)"
        *ngIf="download?.deletable"
      >
        <i
          class="pi pi-times sh-file-download__delete-icon"
          i18n-pTooltip
          pTooltip="Datei löschen"
        ></i>
      </a>
    </li>
  </ul>
</div>
