import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sh-topteam-footer',
  templateUrl: './topteam-footer.component.html',
  styleUrls: ['./topteam-footer.component.scss'],
})
export class TopteamFooterComponent implements OnInit {
  currentYear: number;

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
