import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allergenValue',
})
export class AllergenValuePipe implements PipeTransform {
  transform(allergenKey: string): string {
    let allergenValue = '';
    switch (allergenKey) {
      case 'CELERIAC':
        allergenValue = $localize`Sellerie und Sellerieerzeugnisse`;
        break;
      case 'CRAB':
        allergenValue = $localize`Krebstiere und Krebstiererzeugnisse`;
        break;
      case 'EDIBLENUTS':
        allergenValue = $localize`Schalenfrüchte und Nebenerzeugnisse`;
        break;
      case 'EGGS':
        allergenValue = $localize`Eier und Eiererzeugnisse`;
        break;
      case 'FISH':
        allergenValue = $localize`Fisch & Fischerzeugnisse`;
        break;
      case 'GLUTEN':
        allergenValue = $localize`Glutenhaltiges Getreide & glutenhaltiges Getreideerzeugnisse`;
        break;
      case 'LUPIN':
        allergenValue = $localize`Lupine und Lupinenerzeugnisse`;
        break;
      case 'MILK':
        allergenValue = $localize`Milch und Milcherzeugnisse (einschließlich Lactose)`;
        break;
      case 'MOLLUSCS':
        allergenValue = $localize`Weichtiere und Weichtiererzeugnisse`;
        break;
      case 'MUSTARD':
        allergenValue = $localize`Senf- und Senferzeugnisse`;
        break;
      case 'PEANUTS':
        allergenValue = $localize`Erdnüsse und Erdnusserzeugnisse`;
        break;
      case 'SESAME':
        allergenValue = $localize`Sesamsamen und Sesamsamenerzeugnisse`;
        break;
      case 'SOJA':
        allergenValue = $localize`Soja und Sojaerzeugnisse`;
        break;
      case 'SULFITE':
        allergenValue = $localize`Schwefeldioxid und Sulfite`;
        break;
    }
    return allergenValue;
  }
}
