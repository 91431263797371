import {
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { usernameValidator } from '../../validators/username-validator';
import { passwordValidator } from '@topteam-ui/global-shared/validators/password-validator';
import { emailValidator } from '@topteam-ui/global-shared/validators/email-validator';
import { Gender } from '@topteam-ui/global-shared/model/common/gender';

const formBuilder = new FormBuilder();

export type UserRegistrationForm = ReturnType<typeof buildUserRegistrationForm>;
type ContactForm = ReturnType<typeof buildContactForm>;

export const buildUserRegistrationForm = () =>
  formBuilder.group(
    {
      username: new FormControl<string>('', [
        usernameValidator,
        Validators.required,
      ]),
      email: new FormControl<string>('', [Validators.required, emailValidator]),
      password: new FormControl<string>('', [
        passwordValidator,
        Validators.required,
      ]),
      passwordrepeat: new FormControl<string>('', [Validators.required]),
      contact: buildContactForm() as ContactForm,
    },
    {
      validators: passwordRepeatValidator,
    }
  );

const buildContactForm = () =>
  formBuilder.group({
    firstname: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    lastname: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    gender: new FormControl<string>(Gender.MALE, [Validators.required]),
    title: new FormControl<string>('', [Validators.maxLength(50)]),
    position: new FormControl<string>('NO_POSITION', [Validators.required]),
  });

const passwordRepeatValidator: ValidatorFn = (group: UserRegistrationForm) => {
  const passwordControl = group.controls.password;
  const repeatControl = group.controls.passwordrepeat;
  const error = { passwordsDontMatch: {} };

  return (passwordControl.touched || repeatControl.touched) &&
    passwordControl.value !== repeatControl.value
    ? error
    : undefined;
};
