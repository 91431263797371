import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataStream',
})
export class DataStreamPipe implements PipeTransform {
  transform(dataStreamKey: string): string {
    let dataStreamValue = '';
    switch (dataStreamKey) {
      case 'B2B':
        dataStreamValue = $localize`B2B`;
        break;
      case 'GDSN':
        dataStreamValue = $localize`GDSN`;
        break;
      case 'GS1SYNC':
        dataStreamValue = $localize`GS1 Sync`;
        break;
      case 'ZAS':
        dataStreamValue = $localize`ZAS`;
        break;
    }
    return dataStreamValue;
  }
}
