import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unit',
})
export class UnitPipe implements PipeTransform {
  transform(unitKey: string): string {
    let unitValue = '';
    switch (unitKey) {
      case 'GR':
        unitValue = $localize`Gramm`;
        break;
      case 'KG':
        unitValue = $localize`Kilogramm`;
        break;
      case 'LT':
        unitValue = $localize`Liter`;
        break;
      case 'ML':
        unitValue = $localize`Milliliter`;
        break;
    }
    return unitValue;
  }
}
