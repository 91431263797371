<div class="pt-logistics-three-state-choice">
  <div class="pt-logistics-three-state-choice__radios">
    <p-radioButton
      [name]="radioGroupName"
      [value]="undefined"
      i18n-label
      label="Nicht ausgewählt"
      class="pt-logistics-three-state-choice__radio"
      [formControl]="choiceFormControl"
    ></p-radioButton>
    <p-radioButton
      [name]="radioGroupName"
      [value]="true"
      i18n-label
      label="Ja"
      class="pt-logistics-three-state-choice__radio"
      [formControl]="choiceFormControl"
    ></p-radioButton>
    <p-radioButton
      [name]="radioGroupName"
      [value]="false"
      i18n-label
      label="Nein"
      class="pt-logistics-three-state-choice__radio"
      [formControl]="choiceFormControl"
    ></p-radioButton>
  </div>
  <sh-form-errors [for]="choiceFormControl"></sh-form-errors>
</div>
