<ng-template #mainRouterOutlet>
  <router-outlet></router-outlet>
</ng-template>

<ng-container
  *ngIf="
    loggedInUserChanged$ | async as loggedInUserChanged;
    else mainRouterOutlet
  "
>
  <pt-multi-login-detected
    [loggedInUserChanged]="loggedInUserChanged"
  ></pt-multi-login-detected>
</ng-container>

<p-toast [style]="{ marginTop: '80px' }" position="top-center"></p-toast>
