import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'certificateType',
})
export class CertificateTypePipe implements PipeTransform {
  transform(type: string): string {
    switch (type) {
      case 'IFS_FOOD':
        return $localize`IFS Food`;
      case 'BRC_GLOBAL_STANDARD_FOR_FOOD_SAFETY':
        return $localize`BRC Global Standard for Food Safety`;
      case 'FSSC_22000':
        return $localize`FSSC 22000`;
      case 'IFS_GLOBAL_MARKETS_FOOD':
        return $localize`IFS Global Markets Food`;
      case 'ISO_22000':
        return $localize`ISO 22000`;
      case 'GLOBAL_GAP':
        return $localize`Global GAP`;
      case 'AMA_GAP':
        return $localize`AMA GAP`;
      case 'IFS_HPC':
        return $localize`IFS HPC (Household and personal care products)`;
      case 'BRC_GLOBAL_STANDARD_FOR_CONSUMER_PRODUCTS':
        return $localize`BRC Global Standard for Consumer Products`;
      case 'IFS_LOGISTICS':
        return $localize`IFS Logistics`;
      case 'BRC_GLOBAL_STANDARD_FOR_STORAGE_AND_DISTRIBUTION':
        return $localize`BRC Global Standard for Storage and Distribution`;
      case 'IFS_BROKER':
        return $localize`IFS Broker`;
      case 'BRC_STANDARD_FOR_AGENTS_AND_BROKERS':
        return $localize`BRC Global Standard for Storage and Distribution`;
      case 'IFS_CASH_AND_CARRY':
        return $localize`IFS Cash&Carry`;
      case 'IFS_PACSECURE':
        return $localize`IFS PACsecure (primary and secondary packaging materials)`;
      case 'BRC_GLOBAL_STANDARD_FOR_PACKAGING':
        return $localize`BRC Global Standard for Packaging and Packaging Materials`;
      case 'ORGANIC':
        return $localize`Bio`;
      case 'MSC':
        return $localize`MSC`;
      case 'ASC':
        return $localize`ASC`;
      case 'PACKAGING':
        return $localize`Ver\u{00AD}pa\u{00AD}ckungs\u{00AD}ent\u{00AD}pflich\u{00AD}tung`;
      default:
        return '';
    }
  }
}
