import { Component } from '@angular/core';
import { AuthenticationContext } from './shared/services/authentication/authentication-context';
import { AliveService } from '@topteam-ui/global-shared/services';
import { environment } from '../environments/environment';
import { PrimeNGConfig } from 'primeng/api';
import { CalendarLocalizationService } from '@topteam-ui/global-shared/util/calendar-localization.service';

@Component({
  selector: 'pt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loggedIn = false;
  readonly loggedInUserChanged$ = this.aliveService.loggedInUserChanged$;

  constructor(
    private authContext: AuthenticationContext,
    private aliveService: AliveService,
    private config: PrimeNGConfig,
    private calendarLocalizationService: CalendarLocalizationService
  ) {
    aliveService.configureInterval(environment.keepAliveInterval);

    this.authContext.getUserInformation().subscribe((credentials) => {
      this.loggedIn = !!credentials;
    });

    config.setTranslation(calendarLocalizationService.getLang());
  }
}
