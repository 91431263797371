import { Component } from '@angular/core';
import { TopbarState } from '../secured-header/topbar-state';

@Component({
  selector: 'pt-secured-layout',
  templateUrl: './secured-layout.component.html',
  styleUrls: ['./secured-layout.component.scss'],
})
export class SecuredLayoutComponent {
  constructor(public headerState: TopbarState) {}
}
