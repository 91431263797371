import { Component, forwardRef } from '@angular/core';
import { CustomControlValueAccessor } from '../custom-control-value-accessor/CustomControlValueAccessor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sh-yes-no-switch',
  templateUrl: './yes-no-switch.component.html',
  styleUrls: ['./yes-no-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => YesNoSwitchComponent),
    },
  ],
})
export class YesNoSwitchComponent extends CustomControlValueAccessor<boolean> {
  yesClicked(): void {
    this.writeValue(true);
  }

  noClicked(): void {
    this.writeValue(false);
  }
}
