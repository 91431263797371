import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlHelperService } from '@topteam-ui/global-shared/util/url-helper.service';
import { SupplierRegistrationState } from './supplier-registration-state.model';

@Injectable({
  providedIn: 'root',
})
export class SupplierRegistrationStateService {
  constructor(private http: HttpClient, private urlHelper: UrlHelperService) {}

  getRegistrationState(): Observable<SupplierRegistrationState> {
    return this.http.get<SupplierRegistrationState>(
      this.urlHelper.prepareApiPath('registrationstate')
    );
  }
}
