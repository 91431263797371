import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { isNullOrUndefined } from '../util/object-util';

/**
 * From an `Observable<AbstractControl>, get a descendant `Observable<FormGroup>`.
 *
 * @example
 * `<form [formGroup]="heroFormGroup$ | pluckFormGroup:'weapons' | async">...</form>
 */
@Pipe({
  pure: true,
  name: 'pluckFormGroup',
})
export class PluckForGroupPipePipe implements PipeTransform {
  transform(
    source$: Observable<AbstractControl>,
    path: string | (string | number)[]
  ): Observable<UntypedFormGroup> {
    return source$.pipe(
      filter((source) => !isNullOrUndefined(source)),
      map((source) => source.get(path)),
      filter((child) => child instanceof UntypedFormGroup)
    ) as Observable<UntypedFormGroup>;
  }
}
