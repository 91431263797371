<div class="grid p-align-stretch pt-big-icon">
  <div class="md:col-2 pt-big-icon__icon-box">
    <i
      class="pi pt-big-icon__icon-box__icon {{ icon || false }}"
      [class.pt-big-icon__icon-box__icon--red]="severity === 'error'"
      [class.pi-times]="!icon && severity === 'error'"
      [class.pt-big-icon__icon-box__icon--green]="severity === 'success'"
      [class.pi-check]="!icon && severity === 'success'"
      [class.pt-big-icon__icon-box__icon--yellow]="severity === 'warning'"
      [class.pi-exclamation-triangle]="!icon && severity === 'warning'"
    ></i>
  </div>
  <div class="md:col-10 pt-big-icon__text-box">
    <ng-content></ng-content>
  </div>
</div>
