import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BankingInformation } from '@topteam-ui/global-shared/model/supplier/payment.model';
import { UrlHelperService } from '@topteam-ui/global-shared/util/url-helper.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BankingInformationService {
  constructor(private http: HttpClient, private urlHelper: UrlHelperService) {}

  getBankingInfo(): Observable<BankingInformation> {
    return this.http.get<BankingInformation>(
      this.urlHelper.prepareApiPath('supplier', 'bankinginfo')
    );
  }

  setBankingInfo(bankingInformation: BankingInformation): Observable<void> {
    return this.http.put<void>(
      this.urlHelper.prepareApiPath('supplier', 'bankinginfo'),
      bankingInformation
    );
  }

  verifyToken(token: string): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.urlHelper.prepareApiPath('banking-confirmation', token),
        {}
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }
}
