import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlHelperService } from '@topteam-ui/global-shared/util/url-helper.service';

@Injectable({
  providedIn: 'root',
})
export class TokenRenewService {
  constructor(private http: HttpClient, private urlHelper: UrlHelperService) {}

  //Always returns http status 200 for security reasons
  public renewSignup(username: string): Observable<void> {
    const url = this.urlHelper.prepareApiPath(
      'signup',
      `confirmation?username=${encodeURI(username)}`
    );
    return this.http.put<void>(url, undefined);
  }
}
