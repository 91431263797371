<div class="card">
  <pt-big-icon-text>
    <h2 i18n>Nur noch ein Schritt!</h2>
    <p i18n>
      Sie erhalten in den nächsten Minuten eine
      <strong>E-Mail zur Bestätigung</strong> an die von Ihnen angegebene
      E-Mail-Adresse.<br />Bitte klicken Sie auf den im E-Mail mitgeschickten
      Link, um im eingeloggten Bereich mit der Lieferanten-Registrierung
      fortzufahren.
    </p>
    <a routerLink="/login">
      <p-button i18n-label label="zurück zur Startseite"></p-button>
    </a>
  </pt-big-icon-text>
  <div class="sh-u-separator--small"></div>
</div>
