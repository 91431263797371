import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlHelperService } from '@topteam-ui/global-shared/util/url-helper.service';

/**
 * Fetches model from backend
 */
@Injectable({
  providedIn: 'root',
})
export class SupplierUserPositionService {
  constructor(private http: HttpClient, private urlHelper: UrlHelperService) {}

  public getUserPositions(): Observable<string[]> {
    return this.http.get<string[]>(
      this.urlHelper.prepareApiPath('user-positions')
    );
  }
}
