import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nutrientValue',
})
export class NutrientValuePipe implements PipeTransform {
  transform(nutrientKey: string): string {
    let nutrientValue = '';
    switch (nutrientKey) {
      case 'BREADUNIT':
        nutrientValue = $localize`Broteinheit [BE]`;
        break;
      case 'CARBSAMYLUM':
        nutrientValue = $localize`Kohlenhydrate, davon Stärke`;
        break;
      case 'CARBSPOLYHYDRICALCOHOLS':
        nutrientValue = $localize`Kohlenhydrate, davon mehrwertige Alkohole`;
        break;
      case 'CARBSSUGAR':
        nutrientValue = $localize`Kohlenhydrate, davon Zucker`;
        break;
      case 'CARBSTOTAL':
        nutrientValue = $localize`Kohlenhydrate gesamt`;
        break;
      case 'FATCHOLESTEROL':
        nutrientValue = $localize`Fett, davon Cholesterin`;
        break;
      case 'FATLACTOSE':
        nutrientValue = $localize`Kohlenhydrate Milchzucker`;
        break;
      case 'FATTOTAL':
        nutrientValue = $localize`Fett gesamt [g]`;
        break;
      case 'FATTYACID':
        nutrientValue = $localize`Fett, davon gesättigte Fettsäuren [g]`;
        break;
      case 'KCAL':
        nutrientValue = $localize`Brennwert [kcal]`;
        break;
      case 'KJ':
        nutrientValue = $localize`Brennwert [KJ]`;
        break;
      case 'POLYUNSATURATEDFATTYACID':
        nutrientValue = $localize`Fett, davon mehrfach ungesättigte Fettsäuren`;
        break;
      case 'PROTEIN':
        nutrientValue = $localize`Eiweiß [g]`;
        break;
      case 'ROUGHAGE':
        nutrientValue = $localize`Ballaststofffe [g]`;
        break;
      case 'SALT':
        nutrientValue = $localize`Salz`;
        break;
      case 'UNSATURATEDFATTYACID':
        nutrientValue = $localize`Fett, davon ungesättigte Fettsäuren [g]`;
        break;
    }
    return nutrientValue;
  }
}
