import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ToastService } from '@topteam-ui/global-shared/services';
import { markAllFormControls } from '@topteam-ui/global-shared/util/reactive-form-util';
import { TokenRenewService } from '../../services/token-renew/token-renew.service';

@Component({
  selector: 'pt-resend-email',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.scss'],
})
export class ResendEmailComponent implements OnInit {
  requestSuccessful = false;
  resendEmail: UntypedFormGroup;

  constructor(
    private tokenRenewService: TokenRenewService,
    private formBuilder: UntypedFormBuilder,
    private toaster: ToastService
  ) {}

  ngOnInit(): void {
    this.resendEmail = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  requestNewToken(): void {
    if (!this.resendEmail.valid) {
      markAllFormControls(this.resendEmail);
      this.toaster.formErrorsToast();
      return;
    }

    this.tokenRenewService
      .renewSignup(this.resendEmail.get('username').value)
      .subscribe(() => {
        // returns true anyways
      });

    this.requestSuccessful = true;
  }
}
