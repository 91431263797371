import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable, ObservableInput } from 'rxjs';

/**
 * Turn a Promise, Iterable, Array, or what have you, into an Observable. Useful for binding non-Observable data
 * to Observable input properties.
 * When using with scalar values, remember to wrap them into a single-value array.
 *
 * @example:
 * <car-display [brandName$]="['Lada'] | toObservable"></car-display> <!-- brandName$: Observable<string> -->
 */
@Pipe({
  name: 'toObservable',
  pure: true,
})
export class ToObservablePipe implements PipeTransform {
  transform<T>(value: ObservableInput<T>): Observable<T> {
    return from(value);
  }
}
