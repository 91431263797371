export const environment = {
  production: true,
  routingSettings: {
    useHash: true,
  },
  keepAliveInterval: 10000,

  reservedUserNamePrefixes: ['topteam'],
  reservedUserNames: ['admin'],
};
