<div class="pt-logged-out-header">
  <pt-top-banner></pt-top-banner>
  <div class="pt-logged-out-header__logo-bar">
    <a routerLink="">
      <img
        class="pt-logged-out-header__logo"
        src="libs/global-shared/shared-assets/img/top-team-zentraleinkaufs-gmbh.svg"
      />
    </a>
  </div>
</div>
