import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'salutation',
})
export class SalutationPipe implements PipeTransform {
  transform(salutationKey: string): string {
    switch (salutationKey) {
      case 'MR':
        return $localize`Herr`;
      case 'MS':
        return $localize`Frau`;
    }
    return '';
  }
}
