import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'sh-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent {
  @Input()
  for: UntypedFormControl;

  get errors(): string[] {
    const errors: string[] = [];

    const formErrors = this.for.errors;

    if (!formErrors) {
      return errors;
    }

    Object.keys(formErrors).forEach((error) => {
      switch (error) {
        case 'isNoIntegerNumber':
          errors.push(
            $localize`:@@error-is-no-integer-number:Es muss eine Zahl eingegeben werden.`
          );
          break;

        case 'alreadyExists':
          errors.push(
            $localize`:@@error-already-exists:'${this.for.errors[error].username}' existiert bereits`
          );
          break;

        case 'required':
          errors.push($localize`:@@error-required:darf nicht leer sein`);
          break;

        case 'forbidden':
          errors.push($localize`:@@error-forbidden:muss leer sein`);
          break;

        case 'minlength':
          errors.push(
            $localize`:@@error-min-length:darf nicht kürzer als ${this.for.errors[error].requiredLength} Zeichen sein`
          );
          break;

        case 'maxlength':
          errors.push(
            $localize`:@@error-max-length:darf nicht länger als ${this.for.errors[error].requiredLength} Zeichen sein`
          );
          break;

        case 'lengthBetween':
          errors.push(
            $localize`:@@error-length-between:muss zwischen ${this.for.errors[error].minLength} und ${this.for.errors[error].maxLength} Zeichen lang sein`
          );
          break;

        case 'urlInvalid':
          errors.push($localize`:@@error-url-invalid:keine gültige URL`);
          break;

        case 'phoneNumberInvalid':
          errors.push(
            $localize`:@@error-phone-number-invalid:nur Zahlen und Leerzeichen erlaubt`
          );
          break;

        case 'phoneOrMobileRequired':
          errors.push(
            $localize`:@@error-phone-or-mobile-required:entweder Festnetz oder Mobil muss angegeben werden`
          );
          break;

        case 'onePartnerMinimumRequired':
          errors.push(
            $localize`:@@error-one-partner-minimum:wenn Sie ein (Sub-)-Lizenzinhaber sind, muss mindestens ein Entpflichtungspartner ausgewählt werden`
          );
          break;

        case 'min':
          errors.push(
            $localize`:@@error-min:muss mindestens ${this.for.errors[error].min} sein`
          );
          break;

        case 'max':
          errors.push(
            $localize`:@@error-max:darf maximal ${this.for.errors[error].max} sein`
          );
          break;

        case 'passwordsDontMatch':
          errors.push(
            $localize`:@@error-passwords-dont-match:Passwörter stimmen nicht überein`
          );
          break;

        case 'usernameInvalid':
          errors.push(
            $localize`:@@error-username-invalid:darf nur aus Kleinbuchstaben und Zahlen bestehen und muss zwischen 3-50 Zeichen lang sein`
          );
          break;

        case 'reservedUsernameViolation':
          errors.push(
            $localize`:@@error-reserved-username-violation:darf nicht mit {{prefixes}} beginnen oder {{usernames}} sein`
          );
          break;

        case 'phoneOrMobileNumeric':
          errors.push(
            $localize`:@@error-phone-or-mobile-numeric:nur Zahlen, keine Sonderzeichen`
          );
          break;

        case 'ibanInvalid':
          errors.push(
            $localize`:@@error-iban-invalid:entspricht keinem gültigen IBAN-Format`
          );
          break;

        case 'bicInvalid':
          errors.push(
            $localize`:@@error-bic-invalid:entspricht keinem gültigen BIC-Format`
          );
          break;

        case 'passwordInvalid':
          errors.push(
            $localize`:@@error-password-invalid:Beachten Sie unsere Regeln für Kennwörter`
          );
          break;

        case 'emailInvalid':
          errors.push(
            $localize`:@@error-email-invalid:ist keine gültige E-Mail Adresse`
          );
          break;

        case 'passwordEqualsOldPassword':
          errors.push(
            $localize`:@@error-password-equals-old-password:darf nicht gleich dem alten Passwort sein`
          );
          break;

        case 'invalidPercentageValue':
          errors.push(
            $localize`:@@error-invalid-percentage-value:ungültige Prozentanzahl. Muss >= 0 und <= 99.99 und im Format 99.99 sein`
          );
          break;

        case 'glnInvalid':
          errors.push(
            $localize`:@@error-gln-invalid:muss eine gültige GLN sein`
          );
          break;

        case 'noneSelected':
          errors.push(
            $localize`:@@error-none-selected:mindestens eine Checkbox muss ausgewählt sein`
          );
          break;

        case 'FILE_TOO_LARGE':
          errors.push($localize`:@@error-file-too-large:die Datei ist zu groß`);
          break;

        case 'FILE_REQUIRED':
          errors.push(
            $localize`:@@error-file-required:eine Datei ist verpflichtend hochzuladen`
          );
          break;

        case 'isNoNumber':
          errors.push($localize`:@@error-is-no-number:ist keine Zahl`);
          break;

        case 'asciiLettersOnly':
          errors.push(
            $localize`:@@error-ascii-letters-only:nur Buchstaben erlaubt; keine Sonderzeichen wie ä,ö,ü,ß,é,č etc.`
          );
          break;

        case 'invalidOrganicVerificationCode':
          errors.push(
            $localize`:@@error-invalid-organic-verification-code:Ungültiger BIO-Kontrollcode`
          );
          break;

        case 'noTermsAndConditionsAccepted':
          errors.push(
            $localize`:@@error-no-terms-and-conditionis-accepted:Zustimmung zu AGB erforderlich`
          );
          break;

        case 'emailInUse':
          errors.push(
            $localize`:@@error-email-in-use:Diese E-Mail-Adresse ist bereits an einen anderen User vergeben!`
          );
          break;

        case 'allWarehousesMeansAtLeastOneWarehouseRequired':
          errors.push(
            $localize`:@@error-all-warehouses-means-at-least-one-warehouse-required:Zumindest ein Lager muss befüllt werden!`
          );
          break;

        case 'licenseOrSubLicenseNrRequired':
          errors.push(
            $localize`:@@error-license-or-sublicense-required:Lizenznummer oder Sublizenznummer erforderlich`
          );
          break;

        case 'cannotContainSpace':
          errors.push(
            $localize`:@@error-cannot-contain-spaces:Darf keine Leerzeichen enthalten`
          );
          break;

        case 'invalidDecimalFormat':
          errors.push(
            $localize`:@@error-invalid-decimal-format:Ungültiges Zahlenformat`
          );
          break;

        case 'dateInThePast':
          errors.push(
            $localize`:@@error-date-in-the-past:Datum muss in der Zukunft liegen`
          );
          break;
        case 'textContainsSemicolon':
          errors.push(
            $localize`:@@error-text-contains-semicolon:Text darf kein Semikolon enthalten`
          );
          break;
      }
    });

    return errors;
  }
}
