import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Calendar, LocaleSettings } from 'primeng/calendar';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrUndefined } from '../../util/object-util';
import { isEmptyOrUndefined } from '../../util/string-util';
import { CalendarLocalizationService } from '../../util/calendar-localization.service';

@Component({
  selector: 'sh-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateRangePickerComponent),
    },
  ],
})
export class DateRangePickerComponent implements ControlValueAccessor, OnInit {
  @ViewChild('calendar', { static: true }) calendar: Calendar;
  @Input() bindingMode: BindingMode = 'object';
  @Input() inputId: string;
  @Input() styleClass: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() readonly onChange = new EventEmitter<void>();
  locale: LocaleSettings;

  constructor(readonly loc: CalendarLocalizationService) {}

  ngOnInit(): void {
    this.locale = this.loc.getLang();
  }

  registerOnChange(fn: (value: DateRange | string) => void): void {
    this.calendar.registerOnChange((dateRange: DateRange) => {
      if (isNullOrUndefined(dateRange)) {
        fn(undefined);
        this.onChange.emit();
        return;
      }
      let value: DateRange | string = dateRange;
      if (this.bindingMode === 'json') {
        value = JSON.stringify(dateRange.map((date) => date.toJSON()));
      }
      fn(value);
      this.onChange.emit();
    });
  }

  writeValue(value: string | DateRange): void {
    if (
      !value ||
      (typeof value === 'string' && isEmptyOrUndefined(value as string))
    ) {
      this.calendar.writeValue(undefined);
      return;
    }

    if (typeof value === 'string') {
      const jsonVal = JSON.parse(value as string) as [string, string];
      value = [new Date(jsonVal[0]), new Date(jsonVal[1])];
    }

    this.calendar.writeValue(value);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: Function): void {
    this.calendar.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.calendar.setDisabledState(isDisabled);
  }
}

export type DateRange = [Date, Date];
export type BindingMode = 'object' | 'json';
