import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserInfo } from './user-info.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationContext {
  private userInformation: BehaviorSubject<UserInfo> =
    new BehaviorSubject<UserInfo>(undefined);

  public readonly isLoggedIn$ = this.userInformation.pipe(
    map((value) => !!value)
  );

  public updateUserInformation(value: UserInfo): void {
    this.userInformation.next(value);
  }

  public getUserInformation(): Observable<UserInfo> {
    return this.userInformation;
  }
}
