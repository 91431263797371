import { Injectable } from '@angular/core';

/**
 * Class detecting layout and header clicks. Responsible for holding state used to show or hide subitems of menubar.
 */

@Injectable({
  providedIn: 'root',
})
export class TopbarState {
  public topbarItemClick: boolean;
  public activeTopbarItem: unknown;
  public theme = 'layout-menu-light';

  public onLayoutClick(): void {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = undefined;
    }
    this.topbarItemClick = false;
  }

  public onTopbarItemClick(event: Event, item: unknown): void {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = undefined;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }
}
