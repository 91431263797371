import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatValueIfPresent } from '@topteam-ui/global-shared/util/string-util';
import { UrlHelperService } from '@topteam-ui/global-shared/util/url-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ConfirmTokenService {
  constructor(private http: HttpClient, private urlHelper: UrlHelperService) {}

  // localhost:8080/signup/xxxxxxxxxxxxxxxx/approve
  public confirmToken(token: string): Observable<void> {
    let url = this.urlHelper.prepareApiPath('signup');
    url = concatValueIfPresent(url, '/' + token, '/approve');
    return this.http.get<void>(url);
  }
}
