import { ValidatorFn } from '@angular/forms';

export const passwordValidator: ValidatorFn = (control) => {
  const value = control.value.toString();

  if (value) {
    const regex = new RegExp(
      '^' + // start anchor
        '(?=.*[A-Z])' + // at least 1 uppercase character
        '(?=.*[0-9])' + // at least 1 digit
        '(?=.*[^A-Za-z0-9\\s])' + // at least 1 special characters (negative match on alphanumeric chars, without whitespace)
        '[.\\S]{8,}' + // min length of 8 (match all characters except for whitespace)
        '$' // end anchor
    );

    if (!regex.test(value)) {
      return { passwordInvalid: {} };
    }
  }

  return undefined;
};
