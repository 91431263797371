<div class="layout-footer">
  <div class="grid">
    <div class="col-12 sh-layout-footer__content">
      <div>
        <span class="sh-footer__text">
          &copy; {{ currentYear }}
          <span i18n
            >TOP-TEAM Zentraleinkauf GmbH, Egger-Lienz-Straße 15, A-4050
            Traun</span
          >
        </span>
      </div>
      <div>
        <a
          class="sh-footer__link"
          target="_blank"
          href="http://www.topteam.co.at/de/kontakt-impressum3.html"
          i18n
          >Impressum</a
        >
        <span class="sh-footer__link-separator">|</span>
        <a
          class="sh-footer__link"
          target="_blank"
          href="../../../../assets/Nutzungsbedingungen.pdf"
          i18n
          >Nutzungsbedingungen</a
        >
      </div>
    </div>
  </div>
</div>
