import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-attention-text',
  templateUrl: './attention-text.component.html',
  styleUrls: ['./attention-text.component.scss'],
})
export class AttentionTextComponent {
  @Input()
  text: string;
}
