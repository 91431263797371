<div
  class="grid justify-content-end grid-nogutter pt-top-banner"
  [class.pt-top-banner--sticky]="sticky"
>
  <div class="lg:col-2 md:col-4 col-nogutter">
    <a class="pt-top-banner__link" href="tel:+43507441211">
      <i class="pi pi-mobile pt-top-banner__text--tiny"></i>
      <span class="pt-top-banner__text--tiny">+43 5 07 44 - 1211</span>
    </a>
  </div>
  <div class="lg:col-2 md:col-4 col-nogutter">
    <a class="pt-top-banner__link" href="mailto:topteam@topteam.co.at">
      <i class="pi pi-envelope pt-top-banner__text--tiny"></i>
      <span class="pt-top-banner__text--tiny">topteam&#64;topteam.co.at</span>
    </a>
  </div>
</div>
