import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

/**
 * Wraps 'get' method of `FormGroup` into a Pipe to avoid function calls in templates
 */
@Pipe({
  name: 'getFormControl',
  pure: true,
})
export class GetFormControlPipe implements PipeTransform {
  transform(
    value: AbstractControl,
    path: (string | number)[] | string
  ): UntypedFormControl | undefined {
    const control = value.get(path);
    return control instanceof UntypedFormControl ? control : undefined;
  }
}
