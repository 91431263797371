import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmTokenService } from '../../../services/confirm-token/confirm-token.service';

@Component({
  selector: 'pt-portal-registerconfirm',
  templateUrl: './registerconfirm.component.html',
  styleUrls: ['./registerconfirm.component.scss'],
})
export class RegisterconfirmComponent implements OnInit {
  status: boolean;

  constructor(
    private route: ActivatedRoute,
    private confirmTokenService: ConfirmTokenService
  ) {
    this.status = undefined;
  }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token');
    this.confirmTokenService.confirmToken(token).subscribe(
      () => {
        this.status = true;
      },
      () => {
        this.status = false;
      }
    );
  }
}
