import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly TOAST_DURATION = 4000;

  constructor(private msgService: MessageService) {}

  formErrorsToast(title?: string, text?: string): void {
    if (!title) title = $localize`:@@toast-error-header:Fehler`;
    if (!text)
      text = $localize`:@@toast-error-text:Beim Ausfüllen des Formulars sind Fehler passiert. Bitte beheben Sie diese.`;
    this.errorToast(title, text);
  }

  successToast(title?: string, text?: string): void {
    title = title || $localize`:@@toast-success-header:Erfolgreich`;
    text = text || $localize`:@@toast-success-text:Daten gespeichert`;
    this.showToast('success', title, text);
  }

  infoToast(title: string, text: string): void {
    this.showToast('info', title, text);
  }

  warnToast(title: string, text: string): void {
    this.showToast('warn', title, text);
  }

  errorToast(title: string, text: string): void {
    this.showToast('error', title, text);
  }

  private showToast(severity: string, title: string, text: string): void {
    this.msgService.add({
      severity: severity,
      summary: title,
      detail: text,
      life: this.TOAST_DURATION,
    });
  }
}
