import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RegisterUserCommand } from './register-user-command';
import { UrlHelperService } from '@topteam-ui/global-shared/util/url-helper.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterUserService {
  constructor(private http: HttpClient, private urlHelper: UrlHelperService) {}

  public registerUser(
    user: RegisterUserCommand
  ): Observable<{ username: string }> {
    return this.http.post<{ username: string }>(
      this.urlHelper.prepareApiPath('signup'),
      user
    );
  }
}
