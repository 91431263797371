import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { from, merge, Observable } from 'rxjs';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AliveService } from '@topteam-ui/global-shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private aliveService: AliveService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const isAuthenticated$ = this.authService.isAuthenticated();

    const whenAuthenticated$ = isAuthenticated$.pipe(
      filter((isAuthenticated) => isAuthenticated),
      switchMap(() => this.authService.getLoggedInUser()),
      filter((loggedInUser) => !!loggedInUser),
      tap((loggedInUser) => this.aliveService.start(loggedInUser.username)),
      map(() => true)
    );

    const whenNotAuthenticated$ = isAuthenticated$.pipe(
      filter((isAuthenticated) => !isAuthenticated),
      tap(() => this.navigateToLoginPage(state)),
      map(() => false)
    );

    return merge(whenAuthenticated$, whenNotAuthenticated$);
  }

  private navigateToLoginPage(state: RouterStateSnapshot): Observable<boolean> {
    return from(
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      })
    );
  }
}
