import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'baseUnitValue',
})
export class BaseUnitValuePipe implements PipeTransform {
  transform(baseUnitKey: string): string {
    let baseUnitValue = '';
    switch (baseUnitKey) {
      case 'BOTTLE':
        baseUnitValue = $localize`Flasche`;
        break;
      case 'BOX':
        baseUnitValue = $localize`Kiste`;
        break;
      case 'CARTON':
        baseUnitValue = $localize`Karton`;
        break;
      case 'KILOGRAMM':
        baseUnitValue = $localize`Kilogramm`;
        break;
      case 'PACKAGE':
        baseUnitValue = $localize`Packung`;
        break;
      case 'PIECE':
        baseUnitValue = $localize`Stück`;
        break;
      case 'THM':
        baseUnitValue = $localize`THM für FD-Bereich`;
        break;
      case 'UNIT':
        baseUnitValue = $localize`Einheit`;
        break;
    }
    return baseUnitValue;
  }
}
