<div class="card">
  <h2 class="sh-h2" i18n>Benutzerbestätigung erneut anfordern</h2>
  <p i18n>
    Sie haben versucht Ihren neu angelegten Benutzer zu aktivieren aber der Link
    aus dem E-Mail scheint nicht zu funktionieren? Hier können Sie ein neues
    Email anfordern.
  </p>

  <div class="sh-u-separator--small"></div>

  <form [formGroup]="resendEmail">
    <div>
      <sh-form-label for="name" required="true" i18n>
        Bentzername
      </sh-form-label>
      <input
        pInputText
        name="username"
        type="text"
        id="name"
        formControlName="username"
        class="reset__input"
      />
      <sh-form-errors [for]="resendEmail.get('username')"></sh-form-errors>
      <div class="sh-u-separator--small"></div>
    </div>
    <p-messages severity="success" *ngIf="requestSuccessful">
      <ng-template pTemplate>
        <span i18n>Email für angegebenen Benutzer wurde versandt</span>
      </ng-template>
    </p-messages>

    <div class="sh-u-separator--small"></div>
    <p-button
      i18n-label
      label="Mail anfordern"
      (click)="requestNewToken()"
    ></p-button>
    <div class="sh-u-separator--small"></div>
  </form>
</div>
