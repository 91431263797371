import { Component, Input } from '@angular/core';

/**
 * Card component wrapping card div. Set 'fullHeight' if you want to apply sh-u-full-height class - default
 * value is false. 'headerText' is an easy way to show header information, if you want to display any other type
 * of header, just set the 'header' attribute of the content you want to set as header and remove headerText since
 * both -'headerText' and special header- is not supported.
 */

@Component({
  selector: 'sh-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input()
  fullHeight = false;

  @Input()
  headerText: string;

  @Input()
  headerClass?: string;

  @Input()
  badgeText: string;

  @Input()
  styleClass?: string;
}
