<div
  class="sh-card card no-gutter"
  [ngClass]="styleClass"
  [class.sh-u-full-height]="fullHeight"
>
  <div>
    <ng-content select="[header]" *ngIf="!headerText"></ng-content>
    <h3
      class="sh-card__headline sh-h3"
      [ngClass]="headerClass"
      *ngIf="headerText"
      [innerHTML]="headerText"
      [attr.data-badge]="badgeText"
    ></h3>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
