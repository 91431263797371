<pt-top-banner sticky="true"></pt-top-banner>

<div
  class="layout-wrapper layout-static"
  [ngClass]="headerState.theme"
  (click)="headerState.onLayoutClick()"
>
  <pt-topteam-header></pt-topteam-header>

  <div class="layout-menu-container">
    <div class="layout-menu-logo">
      <a href="#">
        <img
          class="layout-menu-logo__image"
          src="libs/global-shared/shared-assets/img/top-team-zentraleinkaufs-gmbh.svg"
        />
      </a>
    </div>

    <div class="layout-menu-wrapper">
      <p-scrollPanel [style]="{ height: '100%' }">
        <div class="menu-scroll-content">
          <router-outlet name="app-menu"></router-outlet>
        </div>
      </p-scrollPanel>
    </div>
  </div>

  <div class="layout-main layout-main--full-height">
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <sh-topteam-footer></sh-topteam-footer>
</div>
