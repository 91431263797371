import { Component, Input } from '@angular/core';

/**
 * Creates a grid layout divided in small columns for a big
 * icon depending on severity (success,error) and large columns for content.
 */

@Component({
  selector: 'pt-big-icon-text',
  templateUrl: './big-icon-text.component.html',
  styleUrls: ['./big-icon-text.component.scss'],
})
export class BigIconTextComponent {
  @Input() severity: Severity = 'success';
  @Input() icon: string;
}

type Severity = 'success' | 'error' | 'warning';
