import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notExists',
})
export class NotExistsPipe implements PipeTransform {
  transform(value: unknown): boolean {
    return value === undefined || value === null;
  }
}
