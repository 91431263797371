import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { UserRegistrationComponent } from './user-registration/registration/user-registration.component';
import { GlobalSharedModule } from '@topteam-ui/global-shared';
import { RegisterconfirmComponent } from './user-registration/registration/registerconfirm/registerconfirm.component';
import { PasswordForgottenComponent } from './user-registration/password-reset/password-forgotten/password-forgotten.component';
import { PasswordResetComponent } from './user-registration/password-reset/password-reset/password-reset.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './layout/secured-header/header.component';
import { RegistersuccessComponent } from './user-registration/registration/registersuccess/registersuccess.component';
import { AddUserComponent } from './add-user/add-user.component';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PublicHeaderComponent } from './layout/public-header/public-header.component';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { SecuredLayoutComponent } from './layout/secured-layout/secured-layout.component';
import { LoginLandingComponent } from './login-landing/login-landing.component';
import { TopBannerComponent } from './layout/top-banner/top-banner.component';
import { ThemeSwitcherComponent } from './layout/theme-switcher/theme-switcher.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ResendEmailComponent } from './user-registration/resend-email/resend-email.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { FileUploadModule } from 'primeng/fileupload';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { CardModule } from 'primeng/card';
import { PaymentModule } from './supplier-shared/payment/payment.module';
import { MultiLoginDetectedComponent } from './multi-login-detected/multi-login-detected.component';
import { CsrfInitializerService } from '@topteam-ui/global-shared/services/csrf/csrf-initializer.service';
import { Observable } from 'rxjs';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    GlobalSharedModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    DropdownModule,
    KeyFilterModule,
    RadioButtonModule,
    ButtonModule,
    CheckboxModule,
    InputSwitchModule,
    InputMaskModule,
    FileUploadModule,
    OverlayPanelModule,
    InputTextModule,
    ScrollPanelModule,
    MenuModule,
    MessageModule,
    CardModule,
    SharedModule,
    PaymentModule,
  ],
  declarations: [
    AppComponent,
    UserRegistrationComponent,
    RegisterconfirmComponent,
    PasswordForgottenComponent,
    PasswordResetComponent,
    HeaderComponent,
    RegistersuccessComponent,
    AddUserComponent,
    PublicHeaderComponent,
    PublicLayoutComponent,
    SecuredLayoutComponent,
    LoginLandingComponent,
    TopBannerComponent,
    ThemeSwitcherComponent,
    ResendEmailComponent,
    MultiLoginDetectedComponent,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    {
      // Ensures that a CSRF token cookie is obtained from the backend on application startup.
      // Without a CSRF token up-front, the first request to an endpoint
      // that requires CSRF protection (e.g. login) will fail.
      provide: APP_INITIALIZER,
      useFactory: initializeCsrf,
      deps: [CsrfInitializerService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function initializeCsrf(svc: CsrfInitializerService): () => Observable<void> {
  return () => svc.initializeCsrf();
}
