import { Injectable } from '@angular/core';
import { LocaleSettings } from 'primeng/calendar';

@Injectable({
  providedIn: 'root',
})
export class CalendarLocalizationService {
  getLang(): LocaleSettings {
    return {
      firstDayOfWeek: 1,
      dayNames: [
        $localize`Sonntag`,
        $localize`Montag`,
        $localize`Dienstag`,
        $localize`Mittwoch`,
        $localize`Donnerstag`,
        $localize`Freitag`,
        $localize`Samstag`,
      ],
      dayNamesShort: [
        $localize`Son`,
        $localize`Mon`,
        $localize`Die`,
        $localize`Mit`,
        $localize`Don`,
        $localize`Fre`,
        $localize`Sam`,
      ],
      dayNamesMin: [
        $localize`So`,
        $localize`Mo`,
        $localize`Di`,
        $localize`Mi`,
        $localize`Do`,
        $localize`Fr`,
        $localize`Sa`,
      ],
      monthNames: [
        $localize`Jänner`,
        $localize`Februar`,
        $localize`März`,
        $localize`April`,
        $localize`Mai`,
        $localize`Juni`,
        $localize`Juli`,
        $localize`August`,
        $localize`September`,
        $localize`Oktober`,
        $localize`November`,
        $localize`Dezember`,
      ],
      monthNamesShort: [
        $localize`Jän`,
        $localize`Feb`,
        $localize`Mar`,
        $localize`Apr`,
        $localize`Mai`,
        $localize`Jun`,
        $localize`Jul`,
        $localize`Aug`,
        $localize`Sep`,
        $localize`Okt`,
        $localize`Nov`,
        $localize`Dez`,
      ],
      today: $localize`Heute`,
      clear: $localize`Leeren`,
      dateFormat: $localize`dd.mm.yy`,
    };
  }
}
