import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication/authentication.service';
import { AuthenticationContext } from '../../shared/services/authentication/authentication-context';
import { Router } from '@angular/router';
import { AliveService } from '@topteam-ui/global-shared/services';
import { Observable, Subscription } from 'rxjs';
import { TopbarState } from './topbar-state';
import { map, skipWhile, take } from 'rxjs/operators';
import { SupplierService } from '../../services/supplier/supplier.service';
import { SupplierRegistrationStateService } from '../../shared/services/registration-state/supplier-registration-state.service';
import { UserInfo } from '../../shared/services/authentication/user-info.model';

@Component({
  selector: 'pt-topteam-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  userData: UserInfo | undefined = undefined;
  isSupplierActive$ = this.registrationStateService
    .getRegistrationState()
    .pipe(map((state) => state.supplierStatus === 'ACTIVE'));
  userInfo$: Observable<string>;
  private readonly subscriptions$ = new Subscription();
  private supplierName$: Observable<string>;

  constructor(
    private authService: AuthenticationService,
    private authContext: AuthenticationContext,
    private router: Router,
    private aliveService: AliveService,
    private supplierService: SupplierService,
    private registrationStateService: SupplierRegistrationStateService,
    public topbarState: TopbarState
  ) {
    this.subscriptions$.add(
      this.authContext.getUserInformation().subscribe((val) => {
        this.userData = val;
      })
    );
  }

  ngOnInit(): void {
    this.userInfo$ = this.authContext.getUserInformation().pipe(
      take(1),
      map(
        (userInfo) =>
          `${userInfo.contact.title || ''} ${userInfo.contact.fullname}`
      )
    );
    this.supplierName$ = this.supplierService.getMasterData().pipe(
      take(1),
      skipWhile((masterData) => !masterData),
      map((masterData) => `(${masterData.name})`)
    );
  }

  logout(): void {
    this.aliveService.stop();
    this.router.navigate(['login']); // implicitly logs out via LogoutGuard
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  userLoggedIn(): boolean {
    return !!this.userData;
  }
}
