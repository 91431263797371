import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '../util/object-util';

@Pipe({
  pure: true,
  name: 'upperSnakeCase',
})
export class UpperSnakeCasePipe implements PipeTransform {
  transform(value: string): string {
    if (isNullOrUndefined(value)) {
      return value;
    }
    return value.replace(/([A-Z])/g, '_$1').toUpperCase();
  }
}
