import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

const defaultContentType = 'application/json';

@Injectable()
export class ContentNegotiationInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let headers = req.headers;
    headers = this.fixupAcceptHeader(headers);
    headers = this.fixupContentTypeHeader(headers, req.body);
    this.fixupAcceptHeader(headers);
    this.fixupContentTypeHeader(headers, req.body);
    const clonedRequest = req.clone({ headers: headers });

    return next.handle(clonedRequest);
  }

  private fixupAcceptHeader(headers: HttpHeaders): HttpHeaders {
    const accept = headers.get('Accept');
    if (accept) return headers;
    return headers.set('Accept', defaultContentType);
  }

  private fixupContentTypeHeader(
    headers: HttpHeaders,
    body?: unknown
  ): HttpHeaders {
    const contentType = headers.get('Content-Type');
    if (contentType) return headers;
    if (body instanceof FormData) {
      // For multipart/form-data requests, let the browser autodetect Content-Type by not setting it explicitly.
      // This ensures that the multipart boundary string is configured correctly.
      return headers;
    }
    return headers.set('Content-Type', defaultContentType);
  }
}
