import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSharedModule } from '@topteam-ui/global-shared';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';

import { ContactComponent } from './components/contact/contact.component';
import { BigIconTextComponent } from './components/big-icon-text/big-icon-text.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ThreeStateChoiceComponent } from './components/three-state-choice/three-state-choice.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';

const exportedComponents: Array<Type<unknown>> = [
  ContactComponent,
  BigIconTextComponent,
  ThreeStateChoiceComponent,
];

const sharedImports: Array<Type<unknown>> = [
  CommonModule,
  GlobalSharedModule,
  ReactiveFormsModule,
  InputTextModule,
  InputTextareaModule,
  ButtonModule,
  MenuModule,
  RadioButtonModule,
  DropdownModule,
  CheckboxModule,
  RouterModule,
];

@NgModule({
  imports: [...sharedImports],
  declarations: [...exportedComponents, LanguageSwitchComponent],
  exports: [...exportedComponents, ...sharedImports, LanguageSwitchComponent],
})
export class SharedModule {}
