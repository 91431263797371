import { Pipe, PipeTransform } from '@angular/core';
import { Incoterms } from '../model/supplier/incoterms.model';

@Pipe({
  name: 'incotermsValue',
})
export class IncotermsValuePipe implements PipeTransform {
  transform(incotermsKey: Incoterms): string {
    let incotermsValue = '';
    switch (incotermsKey) {
      case 'FREE_WAREHOUSE':
        incotermsValue = $localize`FL - Frei Lager`;
        break;
      case 'UNFREE':
        incotermsValue = $localize`UN - Unfrei`;
        break;
      case 'CLEARED':
        incotermsValue = $localize`DDP - Geliefert verzollt`;
        break;
      case 'CLEARED_DE':
        incotermsValue = $localize`ZDE - Geliefert verzollt (DE)`;
        break;
      case 'DELIVERED_FREE':
        incotermsValue = $localize`DDU - Geliefert unverzollt`;
        break;
      case 'EX_SHIP':
        incotermsValue = $localize`DES - Geliefert ab Schiff`;
        break;
      case 'EX_RAMP':
        incotermsValue = $localize`EXR - Ab Rampe`;
        break;
      case 'FREE_ON_BOARD':
        incotermsValue = $localize`FOB - Frei an Bord`;
        break;
      case 'COST_INSURANCE_FREIGHT':
        incotermsValue = $localize`CIF - Kosten, Versicherung & Fracht`;
        break;
      case 'EX_FACTORY':
        incotermsValue = $localize`EXW - Ab Werk`;
        break;
      case 'DELIVERED_BY_FREIGHTCARRIER':
        incotermsValue = $localize`FCA - Lieferung frei Frachtführer`;
        break;
      case 'FREE_HOUSE':
        incotermsValue = $localize`FH - Lieferung frei Haus`;
        break;
      case 'DELIVERED_TO_KNOWN_LOCATION':
        incotermsValue = $localize`DAP - Lieferung an benannten Ort`;
        break;
    }
    return incotermsValue;
  }
}
