import { Component, Input } from '@angular/core';
import { AliveService } from '@topteam-ui/global-shared/services';
import { LoggedInUserChanged } from '@topteam-ui/global-shared/services/alive-service/alive.service';

@Component({
  selector: 'pt-multi-login-detected',
  templateUrl: './multi-login-detected.component.html',
  styles: [],
})
export class MultiLoginDetectedComponent {
  @Input() loggedInUserChanged: LoggedInUserChanged;

  constructor(private aliveService: AliveService) {}
}
