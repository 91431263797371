<pt-public-header></pt-public-header>

<div class="grid grid-nogutter pt-public-content">
  <div class="md:col-8 col-offset-2">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="sh-u-separator--small"></div>

<sh-topteam-footer></sh-topteam-footer>
