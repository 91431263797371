import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sh-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
})
export class FileDownloadComponent {
  @Input() header: string;
  @Input() headerDisabled: boolean;
  @Output() fileDeleted: EventEmitter<string> = new EventEmitter<string>();

  downloads: FileDownload[];

  @Input()
  set fileDownloads(downloads: FileDownload[] | FileDownload) {
    if (!downloads) {
      this.downloads = undefined;
      return;
    }

    if (downloads instanceof Array) {
      this.downloads = downloads;
    } else {
      this.downloads = [downloads];
    }
  }

  deleteFile(file: FileDownload): void {
    if (file.deletable) {
      this.fileDeleted.emit(file.uuid);
    }
  }
}

export class FileDownload {
  constructor(
    public uuid: string,
    public filename: string,
    public link: string,
    public deletable = true
  ) {}
}
