import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';
import { isNullOrUndefined } from '../util/object-util';

/**
 * Pipe to apply RxJS `pluck` operator to an Observable
 * See https://www.learnrxjs.io/operators/transformation/pluck.html
 *
 * @example
 * Template expression
 * `{{car$ | pluck:'engine':'power' | async}}`
 *    is equivalent to pipe() expression, but avoiding method call in template which is bad practice:
 *    `{{car$.pipe(pluck('engine', 'power')) | async}}` <== don't do this in a template - bad practice!
 */
@Pipe({
  pure: true,
  name: 'pluck',
})
export class PluckPipe implements PipeTransform {
  transform<T, K1 extends keyof T>(
    source$: Observable<T>,
    k1: K1
  ): Observable<T[K1]>;
  transform<T, K1 extends keyof T, K2 extends keyof T[K1]>(
    source$: Observable<T>,
    k1: K1,
    k2: K2
  ): Observable<T[K1][K2]>;
  transform<
    T,
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2]
  >(source$: Observable<T>, k1: K1, k2: K2, k3: K3): Observable<T[K1][K2][K3]>;
  transform<
    T,
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3]
  >(
    source$: Observable<T>,
    k1: K1,
    k2: K2,
    k3: K3,
    k4: K4
  ): Observable<T[K1][K2][K3][K4]>;
  transform<
    T,
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3],
    K5 extends keyof T[K1][K2][K3][K4]
  >(
    source$: Observable<T>,
    k1: K1,
    k2: K2,
    k3: K3,
    k4: K4,
    k5: K5
  ): Observable<T[K1][K2][K3][K4][K5]>;
  transform<
    T,
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3],
    K5 extends keyof T[K1][K2][K3][K4],
    K6 extends keyof T[K1][K2][K3][K4][K5]
  >(
    source$: Observable<T>,
    k1: K1,
    k2: K2,
    k3: K3,
    k4: K4,
    k5: K5,
    k6: K6
  ): Observable<T[K1][K2][K3][K4][K5][K6]>;

  transform<T, R>(
    source$: Observable<T>,
    ...properties: string[]
  ): Observable<R> {
    const pluckFn = pluck(...properties);
    return source$.pipe(
      filter((source) => !isNullOrUndefined(source)),
      pluckFn
    ) as Observable<R>;
  }
}
