import { Component, OnDestroy } from '@angular/core';
import { RegisterUserService } from '../../services/register-user/register-user.service';
import { Router } from '@angular/router';
import { ToastService } from '@topteam-ui/global-shared/services';
import { StatusCodes } from 'http-status-codes';
import { Gender } from '@topteam-ui/global-shared/model/common/gender';
import { SelectItem } from 'primeng/api';
import { SupplierUserPositionService } from './services/supplier-user-position.service';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { UserPositionPipe } from '@topteam-ui/global-shared/pipes/user-position.pipe';
import {
  buildUserRegistrationForm,
  UserRegistrationForm,
} from './user-registration.form-model';
import { RegisterUserCommand } from '../../services/register-user/register-user-command';

@Component({
  selector: 'pt-portal-register',
  templateUrl: './user-registration.component.html',
})
export class UserRegistrationComponent implements OnDestroy {
  userForm: UserRegistrationForm;
  userPositions$: Observable<SelectItem[]>;
  selectableGenders: SelectItem[] = [
    { label: $localize`Herr`, value: Gender.MALE },
    { label: $localize`Frau`, value: Gender.FEMALE },
  ];
  private readonly userPositionPipe = new UserPositionPipe();
  #destroyed$ = new Subject<void>();

  constructor(
    private registrationService: RegisterUserService,
    private userPositionService: SupplierUserPositionService,
    private router: Router,
    private toastService: ToastService
  ) {
    this.userPositions$ = this.userPositionService
      .getUserPositions()
      .pipe(
        map((userPositions) =>
          this.mapUserPositionsToSelectItems(userPositions)
        )
      );

    this.userForm = buildUserRegistrationForm();
  }

  ngOnDestroy() {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  submit(): void {
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.toastService.formErrorsToast();
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { passwordrepeat, ...submitObj } = this.userForm.value;
    const registerUserCommand = submitObj as RegisterUserCommand;

    this.registrationService
      .registerUser(registerUserCommand)
      .pipe(
        takeUntil(this.#destroyed$),
        catchError((error) => {
          this.handleHttpError(error);
          return EMPTY;
        })
      )
      .subscribe(() => this.router.navigate(['registerform-success']));
  }

  private mapUserPositionsToSelectItems(userPositions: string[]): SelectItem[] {
    return userPositions.map(
      (userPosition: string) =>
        ({
          label: this.userPositionPipe.transform(userPosition),
          value: userPosition,
        } as SelectItem)
    );
  }

  private handleHttpError(error: { status: number }): void {
    const usernameCtrl = this.userForm.controls.username;
    switch (error.status) {
      case StatusCodes.CONFLICT:
        usernameCtrl.setErrors({
          alreadyExists: { username: usernameCtrl.value },
        });
        usernameCtrl.markAsTouched();
        break;
      default:
        console.error(error);
    }
  }
}
